import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import LanguageIcon from '@material-ui/icons/Language';
import _ from 'lodash';

import { intlCompareStrings } from 'shared/services/utils';
import { getEnv, getToken, setStore } from 'common/services/token.service';
import LoginToolBar from './LoginToolBar';
import { StoreSelect } from './storeSelect';
import { LanguageSelect } from './languageSelect';
import styles from './index.module.scss';
import { login, setRememberMeDetail, getRememberMeDetail } from './login.service';
import { getAllStores, getTenantByCustomerId } from './storeSelect/storeSelect.service';
import { StoreModel, RememberMeUserInfoModel } from './storeSelect/model';
import { LANGUAGE_SELECTION_ENABLED } from './constant';

interface LoginProps {
  changeLanguagePreference(lang: string): void;
}
const initialStores: StoreModel[] = [];

function Login({ changeLanguagePreference }: LoginProps): JSX.Element {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search || '');
  const redirectURL = searchParams ? searchParams.get('redirect') : '';

  const [errorMessage, setErrorMessage] = useState('');
  const [stores, setStores] = useState(initialStores);
  const [isLoading, setLoading] = useState(false);
  const [isSelectingStore, setSelectingStore] = useState(false);
  const [isSelectingLanguage, setSelectingLanguage] = useState(false);
  const [isBackBtnEnable, enableBackBtn] = useState(false);
  const [versionNumber, setVersionNumber] = useState('');

  const { formatMessage: i18n } = useIntl();
  const labels = {
    customerId: i18n({ id: 'login.customerId' }),
    siteName: i18n({ id: 'login.siteName' }),
    passcode: i18n({ id: 'login.passcode' }),
    nextBtn: i18n({ id: 'login.next' }),
    customerIdEmpty: i18n({ id: 'login.customerIdEmpty' }),
    siteNameEmpty: i18n({ id: 'login.siteNameEmpty' }),
    passcodeEmpty: i18n({ id: 'login.passcodeEmpty' }),
    failedLogin: i18n({ id: 'login.failedLogin' }),
    login: i18n({ id: 'login.login' }),
    language: i18n({ id: 'common.language' }),
  };

  const loginValidationSchema = Yup.object().shape({
    customerId: Yup.string().trim()
      .required(labels.customerIdEmpty),
    passcode: Yup.string()
      .required(labels.passcodeEmpty),
  });

  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue,
  } = useFormik({
    initialValues: {
      customerId: '',
      passcode: '',
    },
    validationSchema: loginValidationSchema,
    async onSubmit() {
      try {
        // TODO : trim values on blur
        const customerId = values.customerId.trim();
        const passcode = values.passcode.trim();
        setErrorMessage('');
        setRememberMeDetail({
          customerId,
        });
        setLoading(true);
        const response = await login(customerId, passcode);
        await setUserStores(response.storeIds); // eslint-disable-line
        setLoading(false);
        setSelectingStore(true);
        enableBackBtn(true);
      } catch (err) {
        setErrorMessage(labels.failedLogin);
        setLoading(false);
      }
    },
  });

  function navigateToLogin(siteId: string, tenantId: string): void {
    setStore(siteId);
    setRememberMeDetail({
      siteId,
    });
    window.loginSuccess(tenantId, siteId, getToken(), getEnv());
    if (redirectURL) {
      history.push(redirectURL);
    }
    history.push('/');
  }

  async function setUserStores(storeIds: string[]): Promise<void> {
    setStores([]); // clear out stores
    const customerId = values.customerId.trim();
    const tenant = await getTenantByCustomerId(customerId.toUpperCase());
    if (tenant && tenant.tenantId) {
      const allStores = await getAllStores(tenant.tenantId);
      const userAssignedStores = _.filter(allStores,
        (store) => _.indexOf(storeIds, store.DOCUMENT_KEY) >= 0);
      if (userAssignedStores.length === 1) {
        const siteId = userAssignedStores[0].DOCUMENT_KEY;
        navigateToLogin(siteId || '', tenant.tenantId || '');
      } else if (userAssignedStores.length > 1) {
        setStores(userAssignedStores.sort(
          /* eslint-disable-next-line  comma-dangle */
          (store1, store2) => intlCompareStrings(store1.name, store2.name)
        ));
      }
    }
  }

  useEffect(() => {
    if (!versionNumber) {
      const version: string = window.fetchVersionNumber();
      setVersionNumber(version);
    }
  }, [versionNumber]);

  useEffect(() => {
    const userInfo: RememberMeUserInfoModel | null = getRememberMeDetail();
    if (userInfo !== null) {
      setFieldValue('customerId', userInfo.customerId);
    }
  }, [setFieldValue]);

  const handleChangeLocal = async (event: React.ChangeEvent): Promise<void> => {
    setErrorMessage('');
    handleChange(event);
  };

  const showLogin = (): void => {
    setSelectingStore(false);
    enableBackBtn(false);
  };

  const setLanguage = (language: string): void => {
    changeLanguagePreference(language);
    setSelectingLanguage(false);
  };

  return (
    <div className={styles.container}>
      <LoginToolBar onBack={showLogin} enableBack={isBackBtnEnable} />
      <div className={styles.loginContainer}>
        <Container component="main" maxWidth="xs">
          {
            (!isSelectingStore && !isSelectingLanguage) && (
              <form onSubmit={handleSubmit} className={styles.loginForm}>
                <Typography className={styles.loginText}>
                  {labels.login}
                </Typography>
                <TextField
                  label={labels.customerId}
                  id="customerId"
                  name="customerId"
                  type="text"
                  fullWidth
                  value={values.customerId}
                  variant="outlined"
                  onChange={handleChangeLocal}
                  helperText={
                    ((touched.customerId) && errors.customerId)
                      ? (touched.customerId) && errors.customerId
                      : ''
                  }
                  error={
                    (touched.customerId && Boolean(errors.customerId)
                      ? touched.customerId && Boolean(errors.customerId)
                      : false)
                  }
                  margin="normal"
                  required
                  autoComplete="customerId"
                />
                <TextField
                  id="passcode"
                  label={labels.passcode}
                  name="passcode"
                  type="password"
                  fullWidth
                  value={values.passcode}
                  variant="outlined"
                  onChange={handleChangeLocal}
                  onBlur={handleBlur}
                  helperText={
                    ((touched.passcode) && errors.passcode)
                      ? (touched.passcode) && errors.passcode
                      : ''
                  }
                  error={
                    (touched.passcode && Boolean(errors.passcode))
                      ? touched.passcode && Boolean(errors.passcode)
                      : false
                  }
                  margin="normal"
                  required
                  autoComplete="current-passcode"
                />
                {errorMessage ? (
                  <Typography color="error" style={{ textAlign: 'center' }}>{errorMessage}</Typography>
                ) : (
                  <div className={styles.nextBtnContainer}>
                    <Button
                      id="loginBtn"
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={styles.submit}
                      disabled={!isValid || isLoading}
                    >
                      {labels.nextBtn}
                    </Button>
                    {isLoading && <CircularProgress size={24} className={styles.progress} />}
                  </div>
                )}
                { LANGUAGE_SELECTION_ENABLED && (
                <div className={styles.languageContainer}>
                  <Button
                    className={styles.languageButton}
                    startIcon={<LanguageIcon />}
                    onClick={(): void => setSelectingLanguage(true)}
                  >
                    {labels.language}
                  </Button>
                </div>
                )}
              </form>
            )
          }
          {
            isSelectingStore && (
              <StoreSelect
                stores={stores}
                onNext={navigateToLogin}
                onCancel={showLogin}
              />
            )
          }
          {
            isSelectingLanguage && (
              <LanguageSelect onSave={(selectedLanguage): void => setLanguage(selectedLanguage)} />
            )
          }
        </Container>
      </div>
      {versionNumber && (
        <Typography className={styles.version}>
          {`v${versionNumber}`}
        </Typography>
      )}
    </div>
  );
}

export default Login;
