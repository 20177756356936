import momentTz from 'moment-timezone';
import moment from 'moment';
import { capitalize, map } from 'lodash';
import { Device } from '../../modules/devices/model';

export const ENV_TYPE = {
  QA: 'qa',
  TL: 'tl',
};
export const Hours24ToSeconds = 24 * 60 * 60;
export const OneHourToSeconds = 60 * 60;
export const OneMinuteToSeconds = 60;

export const dateToString = (date: Date): string => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  return Intl.DateTimeFormat(undefined, options).format(date);
};

export const barcodeToSNFormat = (labelCode: string): string =>
  `0E00${labelCode.substr(2, 2)}${labelCode.substr(0, 2)}00${labelCode.substr(4)}`.toUpperCase();

export const msAsDuration = (time: number,
  daysStr: string,
  hoursStr: string,
  minutesStr: string): string => {
  const endSeconds = Date.now() / 1000;
  const startSeconds = time / 1000;

  let milliseconds = endSeconds - startSeconds;

  const days = Math.floor(milliseconds / Hours24ToSeconds);
  milliseconds -= days * Hours24ToSeconds;

  const hours = Math.floor(milliseconds / OneHourToSeconds);
  milliseconds -= hours * OneHourToSeconds;

  const mins = Math.floor(milliseconds / OneMinuteToSeconds);
  milliseconds -= mins * OneMinuteToSeconds;

  const minsLeft = Math.abs(mins);

  if (!time) {
    return '';
  }

  if (days > 0) {
    return `${days} ${daysStr}`;
  }

  if (hours > 0) {
    return `${hours} ${hoursStr}`;
  }

  if (mins > 0) {
    return `${minsLeft} ${minutesStr}`;
  }

  return '';
};

export const intlCompareStrings = (item1: string, item2: string): number => {
  const COLLATOR = Intl.Collator(undefined, { numeric: true, usage: 'sort' });
  return COLLATOR.compare(item1, item2);
};

export const matchesFilterCriteria = (item: Device, searchData: string): boolean => {
  if (item.positionInStore && item.positionInStore.name) {
    return item.merchandise?.name.toUpperCase().includes(searchData.trim().toUpperCase())
      || (item.merchandise?.brand || '').toUpperCase().includes(searchData.trim().toUpperCase())
      || item.positionInStore.name.toUpperCase().includes(searchData.trim().toUpperCase());
  }
  return item.merchandise?.name.toUpperCase().includes(searchData.trim().toUpperCase())
      || (item.merchandise?.brand || '').toUpperCase().includes(searchData.trim().toUpperCase())
      || false;
};

export const getMillisecondsToStringFormatValue = (milliseconds: number): string => {
  let seconds = milliseconds / 1000;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);

  const hourStr = hours.toString().length === 2 ? hours : `0${hours}`;
  const minutesStr = minutes.toString().length === 2 ? minutes : `0${minutes}`;
  return `${hourStr}:${minutesStr}`;
};

export const getMilisecondsToMinutes = (milliseconds: number): string => {
  const seconds = milliseconds / 1000;
  const minutes = Math.floor(seconds / 60);
  return `${minutes}`;
};

export const truncateString = (str: string, maxLength: number): string =>
  (str.length >= maxLength ? `${str.substring(0, maxLength)}...` : str);

export const convertTimeZone = (date: string, dateFormat: string, tzString: string): string =>
  momentTz.tz(moment(date), tzString).format(dateFormat);

export const firstLetterOfWordInUpperCase = (str: string): string => map(str.split('\n'), (subStr) => map(subStr.split(' '), capitalize).join(' ')).join('\n');

export const getLockRequestSent = (): boolean => !!localStorage.getItem('lockRequestSent');

export const setLockRequestSent = (): void => {
  localStorage.setItem('lockRequestSent', 'true');
};

export const removeLockRequestSent = (): void => {
  localStorage.removeItem('lockRequestSent');
};

export const isQAEnv = (env: string | null): boolean => (env ? env === ENV_TYPE.QA : false);

export const isTLEnv = (env: string | null): boolean => (env ? env === ENV_TYPE.TL : false);
