import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core';
import useLoader from 'shared/hooks/useLoader';
import {
  SecuritySensor, SecureDisplayListViewProps, SecuritySensorSort,
  SecuritySensorListViewItemData, SecuritySensorItemStatus,
} from '../../model';
import { barcodeToSNFormat, matchesFilterCriteria } from '../../../../shared/services/utils';
import styles from './list-view.module.scss';
import { SearchContext } from '../../../app/Context';
import SecureDisplayListItem from './secure-display-list-item';
import {
  isLockable, isSecuritySensorOnline, isAlarming, isArmed,
  isLocked, isInstallPending, isReArmingFailed, getDescriptionBySensorData, isBatteryPowered,
} from '../../secureDisplays-util';

const AllSecuritySensors = ({
  list, iconState, isDataLoaded,
}: SecureDisplayListViewProps):
  JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const isLoading = useLoader({ isDataLoaded });

  const compare = (a: SecuritySensorSort, b: SecuritySensorSort): number => {
    if (a.order === 1 && b.order === 1) {
      return b.currentState?.updateTimeMs - a.currentState?.updateTimeMs;
    }
    return a.order - b.order;
  };

  const getPathWithSerialNumber = (item: SecuritySensor): string => `/secure-displays/device/${barcodeToSNFormat(item.labelCode || '0000')}`;

  const renderSecuritySensorItem = (item: SecuritySensorListViewItemData): JSX.Element => (
    <div key={item.securitySensorData.labelCode}>
      <SecureDisplayListItem
        description={getDescriptionBySensorData(item.securitySensorData)}
        pathname={getPathWithSerialNumber(item.securitySensorData)}
        status={item.status}
        iconState={iconState}
        isLockedDown={item?.securitySensorData?.currentState?.isLockedDown}
        classId={item?.securitySensorData?.classId}
      />
    </div>
  );

  const mapSecurityListItem = (items: SecuritySensor[]): SecuritySensorListViewItemData[] => items
    .filter((item: SecuritySensor) => matchesFilterCriteria(item, searchCriteria))
    .map((currentItem) => {
      let order = 2;
      if (isAlarming(currentItem)) {
        order = 1;
      }
      return { ...currentItem, order };
    })
    .sort((a, b) =>
      a.merchandise?.name.toLowerCase().localeCompare(b.merchandise?.name.toLowerCase()))
    .sort((a, b) => {
      if (a.merchandise?.brand && b.merchandise?.brand) {
        return a.merchandise?.brand.toLowerCase()
        .localeCompare(b.merchandise?.brand.toLowerCase());
      }
      return 0;
    })
    .sort((a, b) => {
      if (a.positionInStore?.name && b.positionInStore?.name) {
        return a.positionInStore?.name.toLowerCase()
        .localeCompare(b.positionInStore?.name.toLowerCase());
      }
      return -1;
    })
    .sort((a, b) => {
      if (a.positionInStore?.department && b.positionInStore?.department) {
        return a.positionInStore?.department.toLowerCase()
        .localeCompare(b.positionInStore?.department.toLowerCase());
      }
      return -1;
    })
    .sort((a, b) => compare(a, b))
    .map((item: SecuritySensor) => {
      const status: SecuritySensorItemStatus = {};
      if (isAlarming(item)) {
        status.isAlarming = true;
      }
      if (isInstallPending(item)) {
        status.isInstallPending = true;
      } else if (!isSecuritySensorOnline(item)) {
        status.isOffline = true;
      }
      if (item.currentState && isArmed(item)) {
        status.isArmed = true;
      }
      if (item.currentState && !isArmed(item)) {
        status.isDisarmed = true;
      }
      if (item.currentState && isBatteryPowered(item)) {
        status.isBatteryPowered = true;
      }
      if (item.currentState && item.classId === 'onepod') {
        if (isLockable(item)) {
          if (isLocked(item)) {
            status.isLocked = true;
          } else {
            status.isUnlocked = true;
          }
        } else {
          status.problemInLock = true;
        }
      }
      if (item.currentState && isReArmingFailed(item)) {
        status.reArmFailed = true;
      }
      return {
        securitySensorData: item,
        status,
      };
    });

  const showHide = isLoading ? styles.hideList : styles.showList;

  return (
    <>
      { isLoading ? <CircularProgress /> : null }
      <Container className={`${styles.container} ${showHide}`}>
        {mapSecurityListItem(list).map(renderSecuritySensorItem)}
      </Container>
    </>
  );
};

export default React.memo(AllSecuritySensors);
