import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { createStyles, DialogActions, DialogContentText, makeStyles } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { powerOffAllTVs, powerOnAllTVs } from './tvSensor.service';
import { TVPowerChangeResponse } from './model';
import { UNKNOWN_STATES, FAILED, IN_PROGRESS, ERROR, UNKNOWN } from './tv-utils';

enum TransitionState { Initial, CommandSent, Processing, Completed }
const ALL = 'all';
const FIVE_SECONDS = 5 * 1000;
const RESPONSE_TIMEOUT = 75 * 1000; // milliseconds

interface TVConfirmDialogProps {
  close: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleTVStateChange: (tvCurrentState: TransitionState) => void;
  isAllOnCommand: boolean;
}

export interface StatusCheckProps {
  currentStatus?: string;
  state: string;
  isOutputHdmiConnected?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      minHeight: '75%',
      maxHeight: '75%',
      minWidth: '85%',
      overflow: 'auto',
    },
    paper: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
    },
    button: {
      lineHeight: '1.75',
      marginTop: '15%',
      width: '55%',
      alignSelf: 'center',
    },
    cancelButton: {
      color: 'black',
    },
    textMessage: {
      textAlign: 'center',
      margin: '10% 0 2% 2%',
    },
    iconDIv: {
      textAlign: 'center',
      margin: '10% 0 2% 2%',
    },
    statusText: {
      textAlign: 'center',
    },
    dialogText: {
      margin: '10%',
      textAlign: 'center',
    },
    action: {
      justifyContent: 'center',
      flex: 'auto',
      marginTop: '25%',
    },
    disabledButton: {
      backgroundColor: '#ECAC00',
      color: '#ECAC00',
      background: '#ECAC00',
    },
    iconImage: {
      color: '#ECAC00',
      fontSize: '50px',
      margin: '10% 0 2% 2%',
    },
  }));

const TVConfirmDialog = (props: TVConfirmDialogProps): JSX.Element => {
  const { close, handleTVStateChange, isAllOnCommand } = props;

  const classes = useStyles();
  const [status, setStatus] = useState(TransitionState.Initial);
  const { formatMessage: i18n } = useIntl();
  const waitingTimeInfoStr = i18n({ id: 'devices.waitingTimeInfo' });
  const cancelStr = i18n({ id: 'devices.cancel' });
  const closeStr = i18n({ id: 'devices.close' });
  const turnOnAllTV = i18n({ id: 'devices.turnOnAllTV' });
  const turnOffAllTV = i18n({ id: 'devices.turnOffAllTV' });
  const commandSentStr = i18n({ id: 'devices.commandSent' });
  const closeWindowWarningInfoStr = i18n({ id: 'devices.closeWindowWarningInfo' });
  const closeWindowStr = i18n({ id: 'devices.closeWindow' });
  const sendingCmdStr = i18n({ id: 'devices.sendingCmd' });
  const turningTvsOn = i18n({ id: 'devices.turningTvsOn' });
  const turningTvsOff = i18n({ id: 'devices.turningTvsOff' });
  const [statusMessage, setStatusMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState(waitingTimeInfoStr);
  const [statusRefreshTimeoutId, setStatusRefreshTimeoutId] = useState<number>(0);
  const [statusCheckMap, setStatusCheckMap] = useState<Map<string, StatusCheckProps>>(new Map());

  const clearRefreshIntervalAndTimeout = (): void => {
    if (statusRefreshTimeoutId) {
      clearTimeout(statusRefreshTimeoutId);
    }
  };

  const markStatusofTvs = (currentStatusCheckMap: Map<string, StatusCheckProps>):
    Map<string, StatusCheckProps> => {
    const updatedStatusCheckMap = new Map(currentStatusCheckMap);
    if (updatedStatusCheckMap.has(ALL)) {
      updatedStatusCheckMap.forEach((value, key) => {
        if (key === ALL) return;
        if (value) {
          const { currentStatus, state, isOutputHdmiConnected } = value;
          if (state === IN_PROGRESS) {
            const newState = isOutputHdmiConnected ? FAILED : ERROR;
            updatedStatusCheckMap.set(key,
              { state: newState, currentStatus, isOutputHdmiConnected });
          }
        }
      });
      updatedStatusCheckMap.delete(ALL);
    }
    return updatedStatusCheckMap;
  };

  const preProcessingStatusMap = (): Map<string, StatusCheckProps> => {
    const newstatusCheckMap = new Map(statusCheckMap);
    newstatusCheckMap.set(ALL, { state: IN_PROGRESS });
    newstatusCheckMap.forEach((value, key) => {
      if (key === ALL) return;
      const { currentStatus, isOutputHdmiConnected } = value;
      let newState;
      if (isOutputHdmiConnected) {
        newState = UNKNOWN_STATES.includes(currentStatus || UNKNOWN) ? FAILED : IN_PROGRESS;
      } else {
        newState = ERROR;
      }
      newstatusCheckMap.set(key, {
        state: newState,
        currentStatus,
        isOutputHdmiConnected,
      });
    });
    return newstatusCheckMap;
  };

  const initializeRefreshIntervalAndTimeout = (): void => {
    clearRefreshIntervalAndTimeout();

    const newStatusRefreshTimeoutId = window.setTimeout(() =>
      setStatusCheckMap((currentStatusCheckMap) => {
        const updatedStatusCheckMap = markStatusofTvs(currentStatusCheckMap);
        setStatusMessage(commandSentStr);
        setStatus(TransitionState.Completed);
        setWarningMessage(closeWindowStr);
        return updatedStatusCheckMap;
      }), RESPONSE_TIMEOUT);
    setStatusRefreshTimeoutId(newStatusRefreshTimeoutId);
  };

  const checkStateChanges = (): void => {
    const newstatusCheckMap = preProcessingStatusMap();
    initializeRefreshIntervalAndTimeout();
    setStatus(TransitionState.CommandSent);
    setStatusMessage(sendingCmdStr);
    setWarningMessage('');
    setStatusCheckMap(new Map(newstatusCheckMap));
  };

  const processStateChanges = async (powerOnOrOffAllTVs: () =>
  Promise<TVPowerChangeResponse>, message: string): Promise<void> => {
    checkStateChanges();

    const timeoutId = window.setTimeout(() => setStatus((currentStatus) => {
      if (currentStatus === TransitionState.CommandSent) {
        setStatusMessage(message);
        setWarningMessage(closeWindowWarningInfoStr);
        return TransitionState.Processing;
      }
      return currentStatus;
    }), FIVE_SECONDS);
    try {
      await powerOnOrOffAllTVs();
    } finally {
      if (status !== TransitionState.Completed) {
        setStatusCheckMap((currentStatusCheckMap) => {
          const updatedStatusCheckMap = markStatusofTvs(currentStatusCheckMap);
          return updatedStatusCheckMap;
        });
        handleTVStateChange(status);
        setStatusMessage(commandSentStr);
        setStatus(TransitionState.Completed);
        setWarningMessage(closeWindowStr);
        clearTimeout(timeoutId);
        clearRefreshIntervalAndTimeout();
      }
    }
  };

  const handleAllOn = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    await processStateChanges(powerOnAllTVs, turningTvsOn);
  };

  const handleAllOff = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    await processStateChanges(powerOffAllTVs, turningTvsOff);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    if (status === TransitionState.Completed) {
      setStatus(TransitionState.Initial);
      setStatusMessage('');
      setWarningMessage(waitingTimeInfoStr);
    }
    handleTVStateChange(status);
    close(event);
  };

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        {isAllOnCommand
          ? (
            <Button size="large" classes={{ disabled: classes.disabledButton }} className={classes.button} disabled={status !== TransitionState.Initial} variant="contained" onClick={handleAllOn} color="primary">
              {turnOnAllTV}
            </Button>
          )
          : (
            <Button size="large" className={classes.button} disabled={status !== TransitionState.Initial} variant="contained" onClick={handleAllOff} color="primary">
              {turnOffAllTV}
            </Button>
          )}
        {status === TransitionState.CommandSent || status === TransitionState.Processing
          ? (
            <div className={classes.iconDIv}>
              <CircularProgress />
              <DialogContentText className={classes.textMessage}>
                {statusMessage}
              </DialogContentText>
            </div>
          ) : null}
        {status === TransitionState.Completed
          ? (
            <>
              <DialogContentText className={classes.statusText}>
                <DoneIcon className={classes.iconImage} />
              </DialogContentText>
              <DialogContentText className={classes.statusText}>
                {statusMessage}
              </DialogContentText>
            </>
          ) : null}
        {warningMessage
          ? (
            <DialogContentText className={classes.textMessage}>
              {warningMessage}
            </DialogContentText>
          ) : null}
        <DialogActions className={classes.action}>
          {status === TransitionState.Processing || status === TransitionState.Completed
            ? (
              <Button className={classes.cancelButton} onClick={handleClose} color="primary">
                {closeStr}
              </Button>
            ) : null}
          {status === TransitionState.Initial
            ? (
              <Button className={classes.cancelButton} onClick={handleClose} color="primary">
                {cancelStr}
              </Button>
            ) : null}
        </DialogActions>
      </Paper>
    </>
  );
};

export default TVConfirmDialog;
