import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useIntl } from 'react-intl';
import { DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { LOADING_STATE } from 'shared/ui/utils/constants';

interface ConfirmDialogProps {
  heading: string;
  message: string;
  onClose: () => void;
  onActionPerform: (action: string) => void;
  actionButtonClicked: boolean;
  ackReceived: boolean;
  isTimeout: boolean;
  timeoutEnd: boolean;
  loadingState: string;
  setLoadingState: (state: string) => void;
  isActionButtonDisabled?: boolean;
  setDisableOtherButtons: (state: boolean) => void;
  disarmAllClicked?: boolean;
}

export interface StatusCheckProps {
  currentStatus?: string;
  state: string;
  isOutputHdmiConnected?: boolean;
}

const useStyles = makeStyles({
  dialogTitle: {
    padding: '0',
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  confirmButton: {
    width: '80%',
    margin: '10px 0',
  },
  confirmationText: {
    whiteSpace: 'pre-line',
    color: '#0000008a',
    margin: '10px 0 20px 0',
  },
  sentConfirmation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sentIcon: {
    display: 'inline-block',
    marginLeft: '8px',
    marginTop: '3px',
  },
  retryingIcon: {
    marginTop: '5px',
    marginLeft: '5px',
  },
  closeIcon: {
    width: '10%',
    marginTop: '-5px',
  },
  modalTitle: {
    width: '80%',
    textAlign: 'center',
  },
  closeButton: {
    color: 'black',
    display: 'block',
    margin: 'auto',
  },
  redCrossIcon: {
    color: '#ff0000',
  },
});

const ConfirmDialog = (props: ConfirmDialogProps): JSX.Element => {
  const {
    heading,
    message,
    onClose,
    onActionPerform,
    actionButtonClicked,
    ackReceived,
    isTimeout,
    timeoutEnd,
    loadingState,
    setLoadingState,
    isActionButtonDisabled,
    setDisableOtherButtons,
    disarmAllClicked,
  } = props;
  const classes = useStyles();
  const { formatMessage: i18n } = useIntl();
  const labels = {
    closeStr: i18n({ id: 'devices.close' }),
    cancelStr: i18n({ id: 'devices.cancel' }),
    disarmAll: i18n({ id: 'devices.disArmAll' }),
    disArmAllCloseWindowInfo: i18n({ id: 'devices.disArmAllCloseInfo' }),
    sent: i18n({ id: 'devices.sent' }),
    retrying: i18n({ id: 'devices.retrying' }),
    errorMessage: i18n({ id: 'devices.errorMessage' }),
  };

  const handleActionPerform = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    onActionPerform('disarm');
    setDisableOtherButtons(true);
  };
  const updateLoadingState = (): void => {
    let loadingStateValue = '';

    if (actionButtonClicked && isTimeout) {
      loadingStateValue = LOADING_STATE.RETRYING;
    } else if (actionButtonClicked && !ackReceived) {
      loadingStateValue = LOADING_STATE.LOADING;
    } else if (actionButtonClicked && timeoutEnd) {
      loadingStateValue = LOADING_STATE.ERROR;
    } else if (actionButtonClicked && ackReceived) {
      loadingStateValue = LOADING_STATE.SENT;
    } else {
      loadingStateValue = '';
    }

    setLoadingState(loadingStateValue);
  };

  const handleClose = (): void => {
    onClose();
    updateLoadingState();
  };

  const getButton = (): JSX.Element => (
    <Button
      variant="contained"
      onClick={handleActionPerform}
      className={classes.confirmButton}
      disabled={isActionButtonDisabled}
      color="primary"
    >
      {heading.toUpperCase()}
    </Button>
  );

  const getButtonState = (): JSX.Element => {
    switch (true) {
      case (actionButtonClicked && timeoutEnd && loadingState === LOADING_STATE.ERROR):
        return <HighlightOffIcon className={classes.redCrossIcon} />;
      case (actionButtonClicked && ackReceived):
        return (
          <div className={`${classes.sentConfirmation} ${classes.confirmationText}`}>
            {labels.sent.toUpperCase()}
            <span className={classes.sentIcon}><CheckCircleOutlineIcon style={{ color: '#257525' }} /></span>
          </div>
        );
      case (actionButtonClicked && isTimeout):
        return (
          <div className={classes.confirmationText}>
            {labels.retrying.toUpperCase()}
            <span className={classes.retryingIcon}><CircularProgress size={24} /></span>
          </div>
        );
      case (actionButtonClicked && !ackReceived):
        return <CircularProgress size={24} />;
      default:
        return getButton();
    }
  };

  return (
    <>
      {
        disarmAllClicked
        && (
          <>
            <DialogTitle className={classes.dialogTitle}>
              <div className={classes.dialogHeader}>
                <IconButton aria-label="close" className={classes.closeIcon} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                <div className={classes.modalTitle}>
                  {heading.toUpperCase()}
                </div>
              </div>
            </DialogTitle>
            <DialogContent className={classes.buttonContainer}>
              <div className={classes.confirmationText}>
                {actionButtonClicked ? labels.disArmAllCloseWindowInfo : message}
              </div>
              {actionButtonClicked ? getButtonState() : getButton()}
              <Button className={classes.closeButton} onClick={handleClose} color="primary">
                {labels.closeStr}
              </Button>
            </DialogContent>
          </>
        )
      }
    </>
  );
};

export default ConfirmDialog;
