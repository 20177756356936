import firebase from 'firebase';

const getFirebaseConfig = () => {
  const fb_config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGID,
    appId: process.env.REACT_APP_APPID
  }
  return fb_config;
};
// Initialize Firebase
firebase.initializeApp(getFirebaseConfig());
export const db = firebase.firestore();
export default firebase;
