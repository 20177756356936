import { OSA_BATTERY_LEVEL } from 'modules/osa/constant';
import { PanicButton } from './model';

export const ONLINE = 'online';
export const OFFLINE = 'offline';
export const INSTALL_PENDING = 'installPending';

export const isPanicButtonOnline = (item: PanicButton): boolean => item.isOnline || false;

export const isInstallPending = (item: PanicButton): boolean => (
  !item.config?.assignedLockdownGroupId
);

export const isIssue = (item: PanicButton): boolean => !isPanicButtonOnline(item)
  || isInstallPending(item);

export const getDeviceStatus = (item: PanicButton): string => {
  if (isInstallPending(item)) {
    return INSTALL_PENDING;
  }
  if (isPanicButtonOnline(item)) {
    return ONLINE;
  }
  return OFFLINE;
};

export const getTimerValue = (endTime: number): string => {
  const currentTime = new Date().getTime();
  const diffTime = endTime - currentTime;
  if (diffTime > 0) {
    const endSeconds = endTime / 1000;
    const startSeconds = currentTime / 1000;

    let seconds = endSeconds - startSeconds;

    const days = Math.floor(seconds / (24 * 60 * 60));
    seconds -= days * 24 * 60 * 60;

    const hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * 60 * 60;

    const mins = Math.floor(seconds / 60);
    seconds -= mins * 60;

    const minsLeft = Math.abs(mins);
    const secondsLeft = Math.floor(Math.abs(seconds));

    // eslint-disable-next-line prefer-template
    return (minsLeft < 10 ? ('0' + minsLeft) : minsLeft) + ':' + (secondsLeft < 10 ? ('0' + secondsLeft) : secondsLeft);
  }

  return '0';
};

export const getBatteryLevel = (item: PanicButton): string => {
  if (item?.currentState?.batteryOk === false) {
    return OSA_BATTERY_LEVEL.low;
  }
  return OSA_BATTERY_LEVEL.ok;
};
