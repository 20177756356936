import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import { getTenant } from 'common/services/token.service';

const Logout = (): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const cancelStr = i18n({ id: 'common.cancel' });
  const confirmStr = i18n({ id: 'common.confirm' });
  const logoutButtonStr = i18n({ id: 'common.logoutBttn' });
  const logoutConfirmationStr = i18n({ id: 'common.confirmLogout' });

  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const logout = (): void => {
    setOpen(false);
    const tenantId = getTenant();
    window.logoutSuccess(tenantId);
    // TODO clear tenant, user and token from store
    window.location.href = ('/login');
  };

  return (
    <>
      <ListItem
        button
        onClick={handleOpen}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary={logoutButtonStr} />
      </ListItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {logoutConfirmationStr}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            {cancelStr}
          </Button>
          <Button variant="contained" onClick={logout} color="primary">
            {confirmStr}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Logout;
