/* eslint-disable  @typescript-eslint/no-explicit-any, no-console */

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import InVueLogo from 'shared/assets/invue-live-logo.svg';
import { GatewayContext } from 'modules/app/GatewayContext';
import GatewayStatusDialog from 'modules/gateways/GatewayStatusDialog';
import { getStore, getTenant } from 'common/services/token.service';
import { fetchGatewayStatus, getGatewaysList, streamGatewaysList, updateGateway } from 'modules/gateways/gateways.service';
import { GatewayModel } from 'modules/gateways/model';
import { OFFLINE, ONLINE, NO_GATEWAY_ASSIGNED } from 'modules/gateways/constants';
import { fetchListOfNotifications, streamListOfNotifications } from 'modules/notificationCenter/notification.service';
import { Notification, NotificationCount } from 'modules/notificationCenter/model';
import PanicButtonBanner from 'modules/panic-buttons/PanicButtonBanner';
import { removeLockRequestSent } from 'shared/services/utils';
import { getStoreById } from 'modules/login/storeSelect/storeSelect.service';
import ListItems from './ListItems';
import Routes from './Routes';
import './index.scss';
import { getAlarmNodeProvisionStatus, streamAlarmNodeProvisionStatus } from '../devices/devices.service';
import { PathName } from './model';
import { getPositionsInSitePlan } from '../planogram/planogram.services';
import { PlanogramContext } from '../app/PlanogramContext';

const useStyle = makeStyles({
  version: {
    textAlign: 'center',
    fontSize: '16px',
    backgroundColor: '#f7f7f7',
    color: '#b7b2b2',
    padding: '5px',
    fontWeight: 500,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  drawer: {
    position: 'relative',
  },
  gateway: {
    width: '100%',
    marginTop: '6px',
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 1,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const defaultItemsCount: NotificationCount = {
  read: 0,
  unread: 0,
};

const Layout = (props: PathName): JSX.Element => {
  const { pathname } = props;
  const [open, setOpen] = useState(false);
  const [versionNumber, setVersionNumber] = useState('');
  const [notificationCount, setNotificationCount] = useState(defaultItemsCount);
  const [alarmProvisionCount, setAlarmProvisionCount] = useState(0);
  const [panicStopTime, setPanicStopTime] = useState(0);
  const { formatMessage: i18n } = useIntl();

  const styles = useStyle();
  const versionStr = i18n({ id: 'common.version' });

  const {
    gatewayCurrentStatus,
    setGatewayCurrentStatus,
    isPanicSituation,
    setIsPanicSituation,
  } = useContext(GatewayContext);

  const { setPositionsInSitePlan } = useContext(PlanogramContext);

  const [isAlarmNodeProvisioned, setIsAlarmNodeProvisioned] = useState(false);
  const [zone, setZone] = useState('');

  useEffect(() => {
    if (gatewayCurrentStatus) {
      window.sendGatewayStatus(gatewayCurrentStatus);
      if (gatewayCurrentStatus === ONLINE || gatewayCurrentStatus === OFFLINE) {
        window.sendAlarmNodeProvisionStatus(isAlarmNodeProvisioned);
      }
    }
  }, [gatewayCurrentStatus, alarmProvisionCount]);

  useEffect(() => {
    console.log('getting positions');
    getPositionsInSitePlan()
      .then((value) => {
        setPositionsInSitePlan(value);
      });
  }, []);

  useEffect(() => {
    if (!versionNumber) {
      const version: string = window.fetchVersionNumber();
      setVersionNumber(version);
    }
  }, [versionNumber]);

  useEffect(() => {
    const tenantId = getTenant();
    const storeId = getStore();
    getStoreById(tenantId, storeId).then((value) => {
      setZone(value?.timeZone);
    });
  }, []);

  const updateGatewayData = async (gatewaysList: GatewayModel[], storeId: string):
    Promise<void> => {
    const gatewaysWithLatestStatus = await Promise.all(gatewaysList.map(async (gateway) => {
      const gatewayStatus = await fetchGatewayStatus(storeId, gateway.serialNumber);
      let updatedGateway = { ...gateway };
      if (gateway.status !== gatewayStatus.status) {
        updatedGateway = {
          ...gateway,
          status: gatewayStatus.status,
        };
        const docId = updatedGateway.DOCUMENT_KEY || '';
        updateGateway(docId, updatedGateway);
      }
      return updatedGateway;
    }));
    if (gatewaysWithLatestStatus.length) {
      const isGatewayOffline = gatewaysWithLatestStatus.some((gateway) =>
        gateway.status === OFFLINE);
      if (isGatewayOffline) {
        setGatewayCurrentStatus(OFFLINE);
      } else {
        setGatewayCurrentStatus(ONLINE);
      }
    } else {
      setGatewayCurrentStatus(NO_GATEWAY_ASSIGNED);
    }
  };

  useEffect(() => {
    const storeId: string = getStore() || '';
    getAlarmNodeProvisionStatus().then((value: number) => {
      setIsAlarmNodeProvisioned(Boolean(value));
      setAlarmProvisionCount(value);
      // eslint-disable-next-line no-console
    }).catch((e) => console.error(e));

    fetchListOfNotifications().then((data: Notification[]) => {
      const filtered: Notification[] = data?.sort((a, b) => (b.createdOn) - (a.createdOn));
      const isPanicNotificationFound = filtered?.some((notification: Notification) => {
        const { device_type: deviceType = '', stopTimeMs = '0' } = notification.notificationInfo || {};

        if (deviceType === 'panicButton') {
          const now = new Date().getTime();
          const stopTime = parseInt(stopTimeMs, 10);
          if (stopTime > now) {
            setPanicStopTime(stopTime);
            setIsPanicSituation(true);
            return true;
          }
        }
        setIsPanicSituation(false);
        return false;
      });

      if (!isPanicNotificationFound) {
        removeLockRequestSent();
      }

      const count: NotificationCount = {
        read: (data?.filter((d) => !d.read))?.length,
        unread: (data?.filter((d) => d.read))?.length,
      };
      setNotificationCount(count);
    });
    getGatewaysList().then(async (gatewaysList: GatewayModel[]) => {
      updateGatewayData(gatewaysList, storeId);
      // eslint-disable-next-line no-console
    }).catch((e) => console.error(e));

    const unsubscribeProvisionStatus = streamAlarmNodeProvisionStatus({
      next: (querySnapshot: any) => {
        const list = querySnapshot.docs.map((item: any) => item.data());
        setIsAlarmNodeProvisioned(Boolean(list.length));
        setAlarmProvisionCount(list.length);
      },
    });

    const unsubscribeNotifications = streamListOfNotifications({
      next: (querySnapshot: any) => {
        const data = querySnapshot.docs.map((item: any) => item.data());
        const filtered: Notification[] = data.sort(
          (a: Notification, b: Notification) => (b.createdOn) - (a.createdOn),
        );

        const isPanicNotificationFound = filtered.some((notification: Notification) => {
          const { device_type: deviceType = '', stopTimeMs = '0' } = notification.notificationInfo || {};

          if (deviceType === 'panicButton') {
            const now = new Date().getTime();
            const stopTime = parseInt(stopTimeMs, 10);
            if (stopTime > now) {
              setPanicStopTime(stopTime);
              setIsPanicSituation(true);
              return true;
            }
          }
          setIsPanicSituation(false);
          return false;
        });

        if (!isPanicNotificationFound) {
          removeLockRequestSent();
        }

        const count: NotificationCount = {
          read: (data.filter((d: Notification) => !d.read)).length,
          unread: (data.filter((d: Notification) => d.read)).length,
        };
        setNotificationCount(count);
      },
    });

    const unsubscribeGatewaysList = streamGatewaysList({
      next: async (querySnapshot: any) => {
        const gatewaysList: GatewayModel[] = querySnapshot.docs.map((item: any) => item.data());

        updateGatewayData(gatewaysList, storeId);
      },
    });

    return (): void => {
      unsubscribeProvisionStatus();
      unsubscribeNotifications();
      unsubscribeGatewaysList();
    };
  }, [setGatewayCurrentStatus]);

  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  return (
    <Container className="container">
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar className="tool-bar">
          <div className="logo-container">
            <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start">
              <MenuIcon />
            </IconButton>
            <img src={InVueLogo} alt="logo" className="app-logo" />
          </div>
          <Link
            to={{
              pathname: '/notifications',
              state: {
                timeZone: zone,
              },
            }}
          >
            <IconButton edge="end">
              <StyledBadge badgeContent={notificationCount.read} color="primary">
                <NotificationsNoneIcon />
              </StyledBadge>
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
      {
        (gatewayCurrentStatus === OFFLINE || gatewayCurrentStatus === NO_GATEWAY_ASSIGNED)
        && pathname !== '/notifications'
        && (
          <div className={styles.gateway}>
            <GatewayStatusDialog />
          </div>
        )
      }
      {isPanicSituation && pathname !== '/notifications' && (
        <PanicButtonBanner panicStopTime={panicStopTime} />
      )}
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        PaperProps={{ className: 'app-drawer' }}
        className={styles.drawer}
      >
        <div>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List component="nav">
          <ListItems toggleDrawer={toggleDrawer} />
        </List>
        {versionNumber && (
          <Typography className={styles.version}>
            {versionStr}
            :
            {versionNumber}
          </Typography>
        )}
      </Drawer>
      <main className="main">
        <Routes />
      </main>
    </Container>
  );
};

export default Layout;
