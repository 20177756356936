/* eslint-disable max-len */
import _ from 'lodash';
import { firstLetterOfWordInUpperCase } from 'shared/services/utils';
import { DescriptionText } from 'shared/ui/device-list/list-item/model';
import { LabelsModel, SecuritySensor } from './model';
import { PlanogramPositionObject } from '../planogram/model';

export const isSecuritySensorOnline = (item: SecuritySensor): boolean => item?.isOnline || false;

export const isReArmingFailed = (item: SecuritySensor): boolean => {
  const reArmIntervalSeconds = 30;
  if (item.lastDisarmEvent && item.currentState
    && (Date.now() <= item.lastDisarmEvent?.commandSentTimeMs + reArmIntervalSeconds)
    && item.currentState.isArmed === false) {
    return true;
  }
  return false;
};

export const isInstallPending = (item: SecuritySensor): boolean => !item.currentState;

export const isComplianceIssue = (
  item: SecuritySensor, positionsInSitePlan: PlanogramPositionObject[],
): boolean => {
  // Get device position data
  const devicePositionName = item.positionInStore?.name;
  const devicePositionDepartment = item.positionInStore?.department;
  // If device is "unassigned"--no position name--it's compliant
  if (!devicePositionName) return false;
  const deviceMerchandiseCode = item.merchandise?.code;
  const deviceMerchandiseUPC = item.merchandise?.deviceUPC;
  // Find matching position in plan
  const sitePlanPosition = positionsInSitePlan.filter((position) =>
    position.name === devicePositionName && position.department === devicePositionDepartment);
  // If no matching position, it's not compliant
  if (!sitePlanPosition.length) return true;
  // If plan has no merchandise, it's compliant
  if (!sitePlanPosition[0].merchandiseName) return false;
  // If matching position exists, compare merchandise with device merchandise
  // If plan merchandise matches device merchandise, it's compliant
  return (sitePlanPosition[0].assignedUpcSku !== deviceMerchandiseCode
    && sitePlanPosition[0].assignedUpcSku !== deviceMerchandiseUPC);
};

const isPositionInPlan = (item: SecuritySensor, positionsInSitePlan: PlanogramPositionObject[]): boolean => {
  // Get device position data
  const devicePositionName = item.positionInStore?.name;
  const devicePositionDepartment = item.positionInStore?.department;
  // If device is "unassigned"--no position name--it's compliant
  if (!devicePositionName) return true;
  // Find matching position in plan
  const sitePlanPosition = positionsInSitePlan.filter((position) =>
    position.name === devicePositionName && position.department === devicePositionDepartment);
  // If no matching position, it's not compliant
  return !!sitePlanPosition.length;
};

export const isLockable = (item: SecuritySensor): boolean | undefined => {
  if (item.classId === 'onepod' && item.lastLockdownEvent && item.lastLockdownEvent?.ackRecd === true) {
    if ((item.lastLockdownEvent?.commandSent === 'lock' && item.currentState.isLockedDown)
      || (item.lastLockdownEvent?.commandSent === 'unlock' && !item.currentState.isLockedDown)) {
      return true;
    }
  }
  return undefined;
};

export const isAlarmNodeOnline = (item: SecuritySensor): boolean => item.isAlarmNodeOnline === true;

export const isAlarming = (item: SecuritySensor): boolean => item.currentState?.isAlarming;

export const isArmed = (item: SecuritySensor): boolean | undefined => item.currentState?.isArmed;

export const isFullyArmed = (item: SecuritySensor): boolean | undefined => item.currentState?.isArmedAsExpected;

export const isBatteryPowered = (item: SecuritySensor): boolean => item.currentState?.isBatteryPowered === true;

export const isAlarmNodeBatteryPowered = (item: SecuritySensor): boolean => item.isAlarmNodeBatteryPowered;

export const isSensorOnWrongStand = (item: SecuritySensor): boolean => item.currentState?.isOnWrongStand === true;

export const isDisarmed = (item: SecuritySensor): boolean | undefined =>
  !isInstallPending(item)
  && isSecuritySensorOnline(item)
  && item.currentState && item.currentState?.isArmed === false;

export const isIssue = (item: SecuritySensor, positionsInSitePlan: PlanogramPositionObject[]): boolean => {
  const { currentState } = item;
  if (!isSecuritySensorOnline(item)
    || isInstallPending(item) || isReArmingFailed(item) || !isArmed(item)
    || isComplianceIssue(item, positionsInSitePlan)
    || !isAlarmNodeOnline(item)
    || isBatteryPowered(item)
    || (currentState
      && (currentState?.isOnWrongStand
        || !currentState?.isPlanogramOk
        || !currentState?.isArmedAsExpected
      ))) {
    return true;
  }
  return false;
};

export const isOnePod = (item: SecuritySensor): boolean => item.classId === 'onepod';

export const isVertical = (item: SecuritySensor): boolean => item.classId === 'vertical';

export const isLocked = (item: SecuritySensor): boolean | undefined => {
  if (item.currentState && item.classId === 'onepod') {
    return item.currentState.isLockedDown;
  }
  return undefined;
};

export const getDescriptionBySensorData = (item: SecuritySensor): DescriptionText => {
  const { brand = '', name: merchandiseName = '' } = item.merchandise || {};
  const { department = '', name: positionName = '' } = item.positionInStore || {};
  let text = '';
  let subText = '';

  if (brand && merchandiseName) {
    text = `${brand}, ${merchandiseName}`;
  } else if (brand && !merchandiseName) {
    text = brand;
  } else if (!brand && merchandiseName) {
    text = merchandiseName;
  }

  if (department && positionName) {
    subText = `${department} | ${positionName}`;
  } else if (department && !positionName) {
    subText = department;
  } else if (!department && positionName) {
    subText = positionName;
  }

  return {
    text, subText, bold: true,
  };
};

export const isPlanogramFlagSet = (item: SecuritySensor): boolean => (item.currentState
  && item.currentState.isPlanogramOk) || false;

export const getIssue = (
  heartbeat: number,
  sensorData: SecuritySensor,
  labels: LabelsModel,
  positionsInSitePlan: PlanogramPositionObject[],
): string => {
  const issue: string[] = [];

  if (isInstallPending(sensorData)) {
    issue.push(labels.installPending);
  } else if (!isSecuritySensorOnline(sensorData)) {
    issue.push(labels.offline);
  } else {
    if (isReArmingFailed(sensorData)) {
      issue.push(labels.failedToRearm);
    }
    if (!isAlarmNodeOnline(sensorData)) {
      issue.push(`\n${labels.attachedAlarmModuleOffline}`);
    }
    if (!isPlanogramFlagSet(sensorData)) {
      issue.push(`\n${labels.confirmationNeeded}`);
    } else {
      if (isComplianceIssue(sensorData, positionsInSitePlan)) {
        // Determine what the issue is
        if (!isPositionInPlan(sensorData, positionsInSitePlan)) {
          issue.push(`\n${labels.positionNotInPlan}`);
        } else {
          issue.push(`\n${labels.merchandiseNotToPlan}`);
        }
      }
      if (sensorData.currentState && sensorData.currentState?.isOnWrongStand) {
        issue.push(`\n${labels.sensorWrongStand}`);
      }
    }
    // Display select list of open loops that should be closed
    if (!sensorData.currentState?.isArmedAsExpected) {
      const { expectedArmedLoops, armedLoop } = sensorData?.currentState || {};
      // Comparing expected armed loop with actual armed loop, to find issue with sensor.
      if (expectedArmedLoops?.apSense !== armedLoop?.apSense
        || expectedArmedLoops?.pigtail !== armedLoop?.pigtail) {
        issue.push(`\n${labels.merchandisePowerConnector}`);
      }
      if (expectedArmedLoops?.auxPort !== armedLoop?.auxPort) {
        issue.push(`\n${labels.auxiliaryPort}`);
      }
    }
  }
  // Display alarming loop
  if (sensorData.currentState && sensorData.currentState.alarmingLoop) {
    const { apSense, pigtail, auxPort, recoiler,
      standPlunger, sensorPlunger, exclusionZoneEntry,
      inclusionZoneExit, magneticDefeat } = sensorData.currentState?.alarmingLoop || {};
    if (apSense) {
      issue.push(`\n${labels.merchandisePowerConnector}`);
    }
    if (pigtail) {
      issue.push(`\n${labels.merchandisePowerConnector}`);
    }
    if (auxPort) {
      issue.push(`\n${labels.auxiliaryPort}`);
    }
    if (recoiler) {
      issue.push(`\n${labels.sensorTetherCable}`);
    }
    if (standPlunger) {
      issue.push(`\n${labels.standPlunger}`);
    }
    if (sensorPlunger) {
      issue.push(`\n${labels.sensorPlunger}`);
    }
    if (exclusionZoneEntry) {
      issue.push(`\n${labels.exclusionZoneEntry}`);
    }
    if (inclusionZoneExit) {
      issue.push(`\n${labels.inclusionZoneExit}`);
    }
    if (magneticDefeat) {
      issue.push(`\n${labels.magneticDefeat}`);
    }
  }
  if (isBatteryPowered(sensorData)) {
    issue.push(`\n${labels.onBatteryPower}`);
  }
  // Remove duplicates
  if (issue.length) {
    const uniqueIssue: string[] = _.uniq(issue);
    if (uniqueIssue[0].charAt(0) === '\n') {
      uniqueIssue[0] = uniqueIssue[0].slice(1);
    }
    return firstLetterOfWordInUpperCase(uniqueIssue.join(''));
    // eslint-disable-next-line
  } else {
    issue.push(labels.none);
    return firstLetterOfWordInUpperCase(issue.join(''));
  }
};

export const isSecurityIssue = (item: SecuritySensor): boolean =>
  !isInstallPending(item) && (
    isAlarming(item)
    || !isSecuritySensorOnline(item)
    || !isAlarmNodeOnline(item)
    || !isArmed(item)
    || !isFullyArmed(item));

export const isNonSecurityIssue = (item: SecuritySensor, positionsInSitePlan: PlanogramPositionObject[]): boolean =>
  isBatteryPowered(item)
  || isComplianceIssue(item, positionsInSitePlan)
  || !isPlanogramFlagSet(item)
  || isSensorOnWrongStand(item);
