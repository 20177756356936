import { getStore, getTenant } from 'common/services/token.service';
import { PlanogramPositionObject } from './model';
import { endpoints } from '../../shared/services/endpoints.constants';
import httpClient from '../../shared/services/http-client';

const getPositionsInSitePlan = async (): Promise<PlanogramPositionObject[]> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No Tenant.');
  }
  const storeId = getStore();
  if (!storeId) {
    throw new Error('No store id.');
  }
  const storeUrl = endpoints.STORE_BY_ID
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const { data: storeData } = await httpClient.get(storeUrl);
  const { sitePlanId } = storeData;
  if (!sitePlanId) {
    return [];
  }
  const sitePlanUrl = endpoints.SITE_PLAN_BY_ID
    .replace('{tenantId}', tenantId)
    .replace('{sitePlanId}', sitePlanId);
  const sitePlan = await httpClient.get(sitePlanUrl);
  const sitePlanData = sitePlan.data;
  return sitePlanData.positionsInSitePlan;
};

export { getPositionsInSitePlan };
