import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useIntl } from 'react-intl';
import { DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { LOADING_STATE } from 'shared/ui/utils/constants';

interface SecuritySensorConfirmDialogProps {
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onLockAll: (status: string) => void;
  actionButtonClicked: boolean;
  ackReceived: boolean;
  isTimeout: boolean;
  timeoutEnd: boolean;
  loadingState: string;
  setLoadingState: (state: string) => void;
  lock: boolean;
  unlock: boolean;
  isActionButtonDisabled?: boolean;
  setDisableOtherButtons?: (state: boolean) => void;
}

export interface StatusCheckProps {
  currentStatus?: string;
  state: string;
  isOutputHdmiConnected?: boolean;
}

const useStyles = makeStyles({
  dialogTitle: {
    padding: '0',
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  lockAllButton: {
    width: '80%',
    margin: '10px 0',
  },
  confirmationText: {
    color: '#0000008a',
    margin: '10px 0 20px 0',
  },
  sentConfirmation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sentIcon: {
    display: 'inline-block',
    marginLeft: '8px',
    marginTop: '3px',
  },
  retryingIcon: {
    marginTop: '5px',
    marginLeft: '5px',
  },
  closeIcon: {
    width: '10%',
    marginTop: '-5px',
  },
  modalTitle: {
    width: '80%',
    textAlign: 'center',
  },
  closeButton: {
    color: 'black !important',
    display: 'block !important',
    margin: 'auto !important',
  },
  redCrossIcon: {
    color: '#ff0000',
  },
});

const SecuritySensorConfirmDialog = (props: SecuritySensorConfirmDialogProps): JSX.Element => {
  const {
    onClose,
    onLockAll,
    actionButtonClicked,
    ackReceived,
    isTimeout,
    timeoutEnd,
    loadingState,
    setLoadingState,
    lock,
    unlock,
    isActionButtonDisabled,
    setDisableOtherButtons,
  } = props;
  const classes = useStyles();
  const { formatMessage: i18n } = useIntl();
  const labels = {
    closeStr: i18n({ id: 'devices.close' }),
    cancelStr: i18n({ id: 'devices.cancel' }),
    lockAll: i18n({ id: 'devices.lockAll' }),
    unlockAll: i18n({ id: 'devices.unLockAll' }),
    lockAllCloseWindowInfo: i18n({ id: 'devices.closeWindowInfo' }, { status: i18n({ id: 'devices.lock' }) }),
    unlockAllCloseWindowInfo: i18n({ id: 'devices.closeWindowInfo' }, { status: i18n({ id: 'devices.unlock' }) }),
    lockConfirmation: i18n({ id: 'devices.lockConfirmation' }),
    unlockConfirmation: i18n({ id: 'devices.unlockConfirmation' }),
    sent: i18n({ id: 'devices.sent' }),
    retrying: i18n({ id: 'devices.retrying' }),
    errorMessage: i18n({ id: 'devices.errorMessage' }),
  };

  const handleLockAll = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    onLockAll('lock');
    if (setDisableOtherButtons) {
      setDisableOtherButtons(true);
    }
  };

  const updateLoadingState = (): void => {
    let loadingStateValue = '';

    if (actionButtonClicked && isTimeout) {
      loadingStateValue = LOADING_STATE.RETRYING;
    } else if (actionButtonClicked && !ackReceived) {
      loadingStateValue = LOADING_STATE.LOADING;
    } else if (actionButtonClicked && timeoutEnd) {
      loadingStateValue = LOADING_STATE.ERROR;
    } else if (actionButtonClicked && ackReceived) {
      loadingStateValue = LOADING_STATE.SENT;
    } else {
      loadingStateValue = '';
    }

    setLoadingState(loadingStateValue);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    onClose(event);
    updateLoadingState();
  };

  const getLockButton = (): JSX.Element => (
    <Button
      variant="contained"
      onClick={handleLockAll}
      className={classes.lockAllButton}
      disabled={isActionButtonDisabled}
      color="primary"
    >
      {labels.lockAll.toUpperCase()}
    </Button>
  );

  const getUnlockButton = (): JSX.Element => (
    <Button
      variant="contained"
      onClick={handleLockAll}
      className={classes.lockAllButton}
      disabled={isActionButtonDisabled}
      color="primary"
    >
      {labels.unlockAll.toUpperCase()}
    </Button>
  );

  const getButtonState = (): JSX.Element => {
    let ButtonStateJSX;
    if (actionButtonClicked && timeoutEnd && loadingState === LOADING_STATE.ERROR) {
      ButtonStateJSX = (
        <HighlightOffIcon className={classes.redCrossIcon} />
      );
    } else if (actionButtonClicked && ackReceived) {
      ButtonStateJSX = (
        <div className={`${classes.sentConfirmation} ${classes.confirmationText}`}>
          {labels.sent.toUpperCase()}
          <span className={classes.sentIcon}><CheckCircleOutlineIcon style={{ color: '#257525' }} /></span>
        </div>
      );
    } else if (actionButtonClicked && isTimeout) {
      ButtonStateJSX = (
        <div className={classes.confirmationText}>
          {labels.retrying.toUpperCase()}
          <span className={classes.retryingIcon}><CircularProgress size={24} /></span>
        </div>
      );
    } else if (actionButtonClicked && !ackReceived) {
      ButtonStateJSX = <CircularProgress size={24} />;
    } else {
      ButtonStateJSX = unlock ? getUnlockButton() : getLockButton();
    }

    return ButtonStateJSX;
  };

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.dialogHeader}>
          <IconButton aria-label="close" className={classes.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <div className={classes.modalTitle}>
            {(lock && !unlock) && labels.lockAll}
            {(!lock && unlock) && labels.unlockAll}
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.buttonContainer}>
        <div className={classes.confirmationText}>
          {
            actionButtonClicked
              ? (lock ? labels.lockAllCloseWindowInfo : labels.unlockAllCloseWindowInfo)
              : (lock ? labels.lockConfirmation : labels.unlockConfirmation)
          }
        </div>
        { actionButtonClicked ? getButtonState() : lock ? getLockButton() : getUnlockButton() }
        <Button className={classes.closeButton} onClick={handleClose} color="primary">
          {labels.closeStr}
        </Button>
      </DialogContent>
    </>
  );
};

export default SecuritySensorConfirmDialog;
