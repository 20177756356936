import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';

import { getLocale, getLocaleStrings } from 'common/i18n';
import Locale from 'common/i18n/locale/locale-model';

import App from 'modules/app';
import Login from 'modules/login';
import { defaultTheme } from 'themes';
import {
  history,
  store,
} from 'store';
import { getPreferredLanguage, setPreferredLanguage } from './modules/login/login.service';
import { AVAILABLE_LANGUAGES, LANGUAGE_SELECTION_ENABLED } from './modules/login/constant';

import './index.scss';
// import registerServiceWorker from './serviceWorkerSetup';
import LoginNativeIntegration from './shared/nativeIntegrationScripts/loginNativeIntegration';

// registerServiceWorker();

interface RootAppProps {
  changeLanguagePreference(lang: string): void;
}

const RootApp = ({ changeLanguagePreference }: RootAppProps): JSX.Element => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={defaultTheme}>
        <Switch>
          <Route path="/login" render={(): JSX.Element => (<Login changeLanguagePreference={changeLanguagePreference} />)} />
          <Route component={App} />
        </Switch>
      </MuiThemeProvider>
      <LoginNativeIntegration />
    </ConnectedRouter>
  </Provider>
);

interface LocalizedAppProps {
  locale: string;
  messages: Record<string, string>;
}
const LocalizedApp = ({ locale, messages }: LocalizedAppProps): JSX.Element => {
  const [preferredLanguageLocal, setPreferredLanguageLocal] = useState(locale);
  const [messagesNew, setMessages] = useState(messages);

  useEffect(() => {
    let storedLanguage: string | null = getPreferredLanguage();
    if (storedLanguage === null) {
      setPreferredLanguage(AVAILABLE_LANGUAGES.ENGLISH);
      storedLanguage = AVAILABLE_LANGUAGES.ENGLISH;
    }
    setPreferredLanguageLocal(storedLanguage);
    getLocaleStrings(storedLanguage).then((localeValues: Locale) => {
      setMessages(flatten(localeValues));
    });
    window.setLanguage(storedLanguage);
  }, []);

  const changeLanguagePreference = (changedLanguage: string): void => {
    getLocaleStrings(changedLanguage).then((localeValues: Locale) => {
      setPreferredLanguage(changedLanguage);
      setPreferredLanguageLocal(changedLanguage);
      setMessages(flatten(localeValues));
      window.setLanguage(changedLanguage);
    });
  };

  return (
    <IntlProvider locale={preferredLanguageLocal} messages={messagesNew}>
      <RootApp changeLanguagePreference={changeLanguagePreference} />
    </IntlProvider>
  );
};

const locale = LANGUAGE_SELECTION_ENABLED ? getLocale() : AVAILABLE_LANGUAGES.ENGLISH;
getLocaleStrings(locale).then((localeValues: Locale) => {
  ReactDOM.render(<LocalizedApp locale={locale} messages={flatten(localeValues)} />, document.getElementById('root'));
});
