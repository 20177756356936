import { AvailableLanguageType } from './model';

export const REMEMBER_ME_USER_INFO = 'rememberMeUserInfo';
export const LANGUAGE_PREFERENCE = 'languagePreference';

export const AVAILABLE_LANGUAGES: AvailableLanguageType = {
  ENGLISH: 'en-Us',
  FRENCH: 'fr',
  JAPANESE: 'ja-JP',
};

export const LANGUAGE_MAP: AvailableLanguageType = {
  ENGLISH: 'English (US)',
  FRENCH: 'Français',
  JAPANESE: '日本の',
};

export const LANGUAGE_SELECTION_ENABLED = true;
