/* eslint-disable @typescript-eslint/no-explicit-any, no-console */
import httpClient from 'shared/services/http-client';
import { getStore, getTenant, getUrl } from 'common/services/token.service';
import { Notification } from './model';
import { endpoints } from '../../shared/services/endpoints.constants';
import { db } from '../../firebase';

const threeMinutesInMilliSeconds = 3 * 60 * 60 * 1000;

export const streamDevicesForAlarmingInfo = (observer: any): Function => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  return db.collection(`${url}/devices`)
    .where('storeId', '==', storeId)
    .onSnapshot(observer);
};

// onSnapshot updated code of fetchListOfNotifications
export const streamListOfReadNotifications = (observer: any): any => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  return db.collection(`${url}/notifications`)
    .where('storeId', '==', storeId)
    .where('read', '==', true)
    .orderBy('createdOn', 'desc')
    .limit(10)
    .onSnapshot(observer);
};

export const streamListOfUnReadNotifications = (observer: any): any => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  return db.collection(`${url}/notifications`)
    .where('storeId', '==', storeId)
    .where('read', '==', false)
    .orderBy('createdOn', 'desc')
    .limit(10)
    .onSnapshot(observer);
};

export const fetchListOfNotifications = async (): Promise<Notification[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = endpoints.GET_LIST_OF_NOTIFICATIONS
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url);
  return response.data;
};

// onSnapshot updated code of fetchListOfNotifications
export const streamListOfNotifications = (observer: any): Function => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  return db.collection(`${url}/notifications`)
    .where('storeId', '==', storeId)
    .onSnapshot(observer);
};

export const deleteSingleNotification = async (notificationId: string): Promise<string> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant/store id');
  }
  const url = endpoints.DELETE_SINGLE_NOTIFICATION
    .replace('{tenantId}', tenantId)
    .replace('{notificationId}', notificationId);
  const response = await httpClient.delete(url);
  return response.data;
};

export const deleteAllNotifications = async (): Promise<string> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  const url = endpoints.DELETE_ALL_NOTIFICATIONS
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.delete(url);
  return response.data;
};

export const updateAllNotifications = async (): Promise<string> => {
  const tenantId = getTenant();
  const storeId = getStore();
  const data = { read: true };
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  const url = endpoints.MARK_ALL_AS_READ
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.put(url, data);
  return response.data;
};

export const updateSingleNotifications = async (notificationId: string): Promise<string> => {
  const tenantId = getTenant();
  const data = { read: true };
  if (!tenantId || !notificationId) {
    throw new Error('No tenant/store id');
  }
  const url = endpoints.UPDATE_SINGLE_NOTIFICATION
    .replace('{tenantId}', tenantId)
    .replace('{notificationId}', notificationId);
  const response = await httpClient.put(url, data);
  return response.data;
};

let lastReadDocument: any; // eslint-disable-line @typescript-eslint/no-explicit-any
let lastUnReadDocument: any; // eslint-disable-line @typescript-eslint/no-explicit-any

export const getDeviceWithAlarmingStatus = async (serialNumber: string): Promise<boolean> => {
  const url = getUrl();
  const device = await db.collection(`${url}/devices`)
    .doc(serialNumber)
    .get();

  const data = device.data();
  const { currentState = {} } = data || {};

  return currentState.isAlarming || false;
};

export const addAlarmingStatus = (docs: any): Promise<Notification[]> => {
  const notifications = docs.map(async (item: any) => {
    const doc = {
      ...item.data(),
      DOCUMENT_KEY: item.id,
    };
    const { createdOn } = doc;
    const { serial_number: serialNumber } = doc.notificationInfo || {};
    const currentTime = new Date().getTime();
    const threeMinutesLaterTime = new Date(createdOn + threeMinutesInMilliSeconds).getTime();

    // as alarm automatically stops after 2 minutes,
    // checking for devices with more than 3 minutes time
    if (currentTime < threeMinutesLaterTime) {
      return {
        ...doc,
        isAlarming: await getDeviceWithAlarmingStatus(serialNumber),
      };
    }

    return {
      ...doc,
      isAlarming: false,
    };
  });

  return Promise.all(notifications);
};

export const getFirstReadNotifications = async (): Promise<Notification[]> => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const result = await db.collection(`${url}/notifications`)
    .where('storeId', '==', storeId)
    .where('read', '==', true)
    .orderBy('createdOn', 'desc')
    .limit(10)
    .get();

  lastReadDocument = result.docs[result.docs.length - 1];
  return addAlarmingStatus(result.docs);
};

export const getReadNotifications = async (): Promise<Notification[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  const url = getUrl();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  let notification: Notification[] = [];
  if (lastReadDocument) {
    const result = await db.collection(`${url}/notifications`)
      .where('storeId', '==', storeId)
      .where('read', '==', true)
      .orderBy('createdOn', 'desc')
      .startAfter(lastReadDocument)
      .limit(10)
      .get();

    lastReadDocument = result.docs[result.docs.length - 1];
    result.docs.forEach((item) => {
      notification = [...notification, {
        ...item.data(),
        DOCUMENT_KEY: item.id,
      }] as Notification[];
    });
  }
  return notification;
};

export const getUnReadNotifications = async (): Promise<Notification[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  const url = getUrl();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  let notification: Notification[] = [];
  if (lastUnReadDocument) {
    const result = await db.collection(`${url}/notifications`)
      .where('storeId', '==', storeId)
      .where('read', '==', false)
      .orderBy('createdOn', 'desc')
      .startAfter(lastUnReadDocument)
      .limit(10)
      .get();

    lastUnReadDocument = result.docs[result.docs.length - 1];
    result.docs.forEach((item: any) => {
      notification = [...notification, {
        ...item.data(),
        DOCUMENT_KEY: item.id,
      }];
    });
  }
  return notification;
};

export const getAllUnReadNotifications = async (): Promise<Notification[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  const url = getUrl();

  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  let notification: Notification[] = [];
  const result = await db.collection(`${url}/notifications`)
    .where('storeId', '==', storeId)
    .where('read', '==', false)
    .orderBy('createdOn', 'desc')
    .get();

  result.docs.forEach((item: any) => {
    notification = [...notification, {
      ...item.data(),
      DOCUMENT_KEY: item.id,
    }];
  });
  return notification;
};

export const getAllReadNotifications = async (): Promise<Notification[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  const url = getUrl();

  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  let notification: Notification[] = [];
  const result = await db.collection(`${url}/notifications`)
    .where('storeId', '==', storeId)
    .where('read', '==', true)
    .orderBy('createdOn', 'desc')
    .get();

  result.docs.forEach((item: any) => {
    notification = [...notification, {
      ...item.data(),
      DOCUMENT_KEY: item.id,
    }];
  });
  return notification;
};

export const getFirstUnReadNotifications = async (): Promise<Notification[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  const url = getUrl();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const result = await db.collection(`${url}/notifications`)
    .where('storeId', '==', storeId)
    .where('read', '==', false)
    .orderBy('createdOn', 'desc')
    .limit(10)
    .get();
  lastUnReadDocument = result.docs[result.docs.length - 1];

  return addAlarmingStatus(result.docs);
};
