import ILocale from './locale/locale-model';

const navigatorLocale = (navigator.language && navigator.languages[0])
  || navigator.language
  || 'en-US';

export const getLocale = (): string => navigatorLocale;

export const getLocaleStrings = async (localeName: string = navigatorLocale): Promise<ILocale> => {
  try {
    return (await import(`./locale/${localeName}`)).default;
  } catch (e) {
    return (await import(`./locale/en-US`)).default;
  }
}
