import Brightness3Icon from '@material-ui/icons/Brightness3';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import styles from './TVSleepModeAlert.module.scss';

interface TVSleepModeViewProps {
  sleepModeDuration?: string;
}

const TVSleepModeAlert = (props: TVSleepModeViewProps): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const { sleepModeDuration } = props;

  const sleepModeStr = i18n({ id: 'dashboard.sleepMode' });
  const functionLimitedStr = i18n({ id: 'dashboard.functionsLimited' });

  const sleepModeUntil = sleepModeDuration ? `${functionLimitedStr} ${sleepModeDuration}` : functionLimitedStr;

  return (
    <div className={styles.sleepModeDiv}>
      <Grid container spacing={2} justify="center">
        <Grid item className={styles.sleepModeIconGrid}>
          <Brightness3Icon className={styles.sleepModeIcon} />
        </Grid>
        <Grid item>
          <Typography className={styles.sleepModeText}>{sleepModeStr}</Typography>
          <Typography className={styles.sleepModeText}>{sleepModeUntil}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default TVSleepModeAlert;
