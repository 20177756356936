import React, { useState, useEffect } from 'react';
import { Button, Typography, TextField, InputAdornment, List, ListItem, ListItemText } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { getTenant } from 'common/services/token.service';

import styles from './index.module.scss';
import { StoreModel, RememberMeUserInfoModel } from './model';
import { getRememberMeDetail } from '../login.service';

interface Props {
  stores: StoreModel[];
  onNext(storeId: string, tenantId: string): void;
  onCancel(): void;
}

export const StoreSelect = (props: Props): JSX.Element => {
  const { stores, onNext, onCancel } = props;
  const [selectedStore, setSelectedStore] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedStores, setSearchedStores] = useState(stores);

  const { formatMessage: i18n } = useIntl();
  const labels = {
    nextBtn: i18n({ id: 'login.next' }),
    cancelBtn: i18n({ id: 'common.cancel' }),
    storeSelect: i18n({ id: 'store.storeSelect' }),
    noStoresAssociated: i18n({ id: 'login.noStoresAssociated' }),
    searchHelperText: i18n({ id: 'common.search' }),
  };

  useEffect(() => {
    const userInfo: RememberMeUserInfoModel | null = getRememberMeDetail();
    if (userInfo?.siteId) {
      setSelectedStore(userInfo?.siteId);
    }
  }, []);

  const handleSearchTermChange = (searchText: string): void => {
    setSearchTerm(searchText);
    const filteredStores = _.filter(stores,
      (store) => (store.name.toLowerCase().includes(searchText.toLowerCase())));
    setSearchedStores(filteredStores);
    setSelectedStore('');
  };

  return (
    <div className={styles.storeSelectContainer}>
      <Typography className={styles.selectStoreLabel} variant="h4">
        {labels.storeSelect}
      </Typography>
      <div style={{ padding: '10px' }}>
        <TextField
          id="search-store"
          label={labels.searchHelperText}
          variant="outlined"
          value={searchTerm}
          onChange={(event): void => handleSearchTermChange(event.target.value)}
          fullWidth
          className={styles.storeSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {
          stores.length > 0 ? (
            <List component="nav" dense className={styles.storeList}>
              {searchedStores.map((store) => (
                <ListItem
                  selected={selectedStore === store.DOCUMENT_KEY}
                  onClick={(): void => setSelectedStore(store.DOCUMENT_KEY || '')}
                  key={store.DOCUMENT_KEY}
                >
                  <ListItemText
                    primary={store.name}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <div className={styles.noStores}>
              {labels.noStoresAssociated}
            </div>
          )
        }
      </div>
      <div className={styles.btnContainer}>
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={onCancel}
        >
          {labels.cancelBtn}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          disabled={!selectedStore || stores.length === 0}
          onClick={(): void => onNext(selectedStore, getTenant() || '')}
        >
          {labels.nextBtn}
        </Button>
      </div>
    </div>
  );
};
