import React, { useContext } from 'react';
import { ListItemErrorStatus } from 'shared/ui/device-list/list-item/model';
import { useIntl } from 'react-intl';
import { ListViewProps } from './model';
import { TVSensor, TVSensorSort } from '../../model';
import TVListView, { TVListViewItemData } from './list-view';
import {
  isDisconnected,
  isIssue,
  isProvisioned,
  isPowerStateUnknown,
  isLowBattery,
  matchesFilterCriteria,
  isOuputHDMIUnplugged,
} from '../../tvSensor.service';
import { SearchContext } from '../../../app/Context';

const TVIssuesList = ({ list }: ListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const { formatMessage: i18n } = useIntl();
  const offlineStr = i18n({ id: 'common.offline' });
  const installPendingStr = i18n({ id: 'devices.installPending' });
  const lowBatteryStr = i18n({ id: 'common.lowBattery' });
  const powerStateUnknownStr = i18n({ id: 'devices.powerStateUnknown' });
  // const noACPowerStr
  const noHDMIConnectionStr = i18n({ id: 'devices.hdmiUnknown' });

  const compare = (a: TVSensorSort, b: TVSensorSort): number => a.order - b.order;

  // TODO : LIVE-313 Add this type of issue in future
  // On Battery(External power disconnected - this is not functional in devices today)
  const mapTVtoListItemData = (items: TVSensor[]): TVListViewItemData[] => items
    .filter((item: TVSensor) => isIssue(item)
      && matchesFilterCriteria(item, searchCriteria))
    .map((currentItem) => {
      let order = 6;
      if (!isProvisioned(currentItem)) {
        order = 1;
      } else if (isDisconnected(currentItem)) {
        order = 2;
      } else if (isOuputHDMIUnplugged(currentItem)) {
        order = 3;
      } else if (isPowerStateUnknown(currentItem)) {
        order = 4;
      } else if (isLowBattery(currentItem)) {
        order = 5;
      }
      return { ...currentItem, order };
    }).sort((a, b) => compare(a, b))
    .map((item: TVSensorSort) => {
      let statusText = '';
      if (!isProvisioned(item)) {
        statusText = installPendingStr;
      } else if (isDisconnected(item)) {
        statusText = offlineStr;
      } else if (isOuputHDMIUnplugged(item)) {
        statusText = noHDMIConnectionStr;
      } else if (isPowerStateUnknown(item)) {
        statusText = powerStateUnknownStr;
      } else if (isLowBattery(item)) {
        statusText = lowBatteryStr;
      }
      const status = {
        text: statusText,
        errorStatus: ListItemErrorStatus.warning,
      };
      return {
        TVData: item,
        status,
      };
    });

  return <TVListView list={mapTVtoListItemData(list)} />;
};

export default TVIssuesList;
