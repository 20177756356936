import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { GatewayContext } from 'modules/app/GatewayContext';
import { OFFLINE } from './constants';
import styles from './GatewayStatusDialog.module.scss';

const GatewayStatusDialog = (): JSX.Element => {
  const { formatMessage: i18n } = useIntl();

  const { gatewayCurrentStatus } = useContext(GatewayContext);

  const gatewayOfflineStr = i18n({ id: 'devices.gatewayOffline' });
  const noGatewayAssignedStr = i18n({ id: 'devices.noGatewayAssigned' });

  const getGatewayStatus = (): string => (gatewayCurrentStatus === OFFLINE
    ? gatewayOfflineStr : noGatewayAssignedStr);

  return (
    <div className={styles['gateway-status-container']}>
      <Grid container spacing={2} justify="center">
        <Grid item className={styles['status-icon-container']}>
          <ReportProblemOutlinedIcon className={styles['status-icon']} />
        </Grid>
        <Grid item className={styles['status-icon-container']}>
          <Typography className={styles['gateway-status-text']}>{getGatewayStatus()}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default GatewayStatusDialog;
