import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TvIcon from '@material-ui/icons/Tv';
import AlarmIcon from '@material-ui/icons/Alarm';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CropFreeIcon from '@material-ui/icons/CropFree';
import LockIcon from '@material-ui/icons/Lock';
import ScannerIcon from '@material-ui/icons/Scanner';
import LensIcon from '@material-ui/icons/Lens';
import Logout from '../logout';

interface ListItemProps {
  toggleDrawer: () => void;
}

const ListItems = ({ toggleDrawer }: ListItemProps): JSX.Element => {
  const intl = useIntl();
  const { formatMessage: i18n } = intl;

  return (
    <div>
      <ListItem
        component={Link}
        button
        to="/"
        onClick={toggleDrawer}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={i18n({ id: 'common.actionBoard' })} />
      </ListItem>
      <Divider />
      <ListItem
        component={Link}
        button
        to="/devices/scan"
        onClick={toggleDrawer}
      >
        <ListItemIcon>
          <CropFreeIcon />
        </ListItemIcon>
        <ListItemText primary={i18n({ id: 'common.scanDevice' })} />
      </ListItem>
      <Divider />
      <ListItem
        component={Link}
        button
        to="/osa"
        onClick={toggleDrawer}
      >
        <ListItemIcon>
          <AssignmentTurnedInIcon />
        </ListItemIcon>
        <ListItemText primary={i18n({ id: 'common.osa' })} />
      </ListItem>
      <ListItem
        component={Link}
        button
        to="/tvs"
        onClick={toggleDrawer}
      >
        <ListItemIcon>
          <TvIcon />
        </ListItemIcon>
        <ListItemText primary={i18n({ id: 'common.tvs' })} />
      </ListItem>
      <ListItem
        component={Link}
        button
        to="/alarms"
        onClick={toggleDrawer}
      >
        <ListItemIcon>
          <AlarmIcon />
        </ListItemIcon>
        <ListItemText primary={i18n({ id: 'common.alarmModules' })} />
      </ListItem>
      <Divider />
      <ListItem
        component={Link}
        button
        to="/secure-displays"
        onClick={toggleDrawer}
      >
        <ListItemIcon>
          <LockIcon />
        </ListItemIcon>
        <ListItemText primary={i18n({ id: 'common.displaySecurity' })} />
      </ListItem>
      <ListItem
        component={Link}
        button
        to="/gateways"
        onClick={toggleDrawer}
      >
        <ListItemIcon>
          <ScannerIcon />
        </ListItemIcon>
        <ListItemText primary={i18n({ id: 'common.gateways' })} />
      </ListItem>
      <ListItem
        component={Link}
        button
        to="/panic-buttons"
        onClick={toggleDrawer}
      >
        <ListItemIcon>
          <LensIcon />
        </ListItemIcon>
        <ListItemText primary={i18n({ id: 'common.panicButtons' })} />
      </ListItem>
      <Divider />
      <Logout />
      <Divider />
    </div>
  );
};

export default ListItems;
