import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { getTenant, getToken } from 'common/services/token.service';
import Layout from 'modules/layout';
import { getLockRequestSent, setLockRequestSent } from 'shared/services/utils';
import { lockUnlockAllOnePodSensors } from 'modules/secure-displays/secureDisplays.service';
import { SearchContext } from './Context';
import { GatewayContext } from './GatewayContext';
import { PlanogramContext } from './PlanogramContext';

const initialPositionsValue = [{
  assignedUpcSku: '',
  department: '',
  deviceType: '',
  merchandiseName: '',
  merchandiseSpacing: '',
  name: '',
  positionDepth: '',
}];

const App = ({ location }: RouteComponentProps): JSX.Element => {
  const isToken = !!getToken();
  const { pathname, search } = location;
  const [searchValue, setSearchValue] = useState('');
  const [gatewayCurrentStatus, setGatewayCurrentStatus] = useState('');
  const [positionsInSitePlan, setPositionsInSitePlan] = useState(initialPositionsValue);
  const [isPanicSituation, setIsPanicSituation] = useState(false);

  useEffect(() => {
    const tenantId = getTenant();
    const isLockRequestSent = getLockRequestSent();
    if (isPanicSituation && tenantId && !isLockRequestSent) {
      lockUnlockAllOnePodSensors(true);
      setLockRequestSent();
    }
  }, [isPanicSituation]);

  return (
    <div>
      {!isToken
        ? (
          <Redirect to={{
            pathname: '/login',
            search: `?redirect=${pathname + search}`,
          }}
          />
        )
        : (
          <SearchContext.Provider
            value={{
              searchCriteria: searchValue,
              updateSearch: setSearchValue,
            }}
          >
            <GatewayContext.Provider
              value={{
                gatewayCurrentStatus,
                setGatewayCurrentStatus,
                isPanicSituation,
                setIsPanicSituation,
              }}
            >
              <PlanogramContext.Provider
                value={{
                  positionsInSitePlan,
                  setPositionsInSitePlan,
                }}
              >
                <Layout pathname={pathname} />
              </PlanogramContext.Provider>
            </GatewayContext.Provider>
          </SearchContext.Provider>
        )}
    </div>
  );
};
export default App;
