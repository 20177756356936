export const TV_BATTERY_THRESHOLD = {
  low: {
    min: 3001,
    max: 3150,
  },
  ok: 3151,
  critical: 3000,
};

export const TV_BATTERY_LEVEL = {
  ok: 'ok',
  low: 'low',
  critical: 'critical',
};

export const TV_HEART_BEAT_DEFAULT = 3600000;
