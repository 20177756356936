interface Action {
  type: string;
  payload: string;
}

type AppState = Array<string>;

const reducer = (): AppState => [''];

export default reducer;
