export const endpoints = {
  ALL_DEVICES_BY_SERIAL_NUMBER: `/v1/model/tenants/staging/devices/{serNum}`,
  PROVISIONED_DEVICES: `/v1/model/tenants/{tenantId}/devices?storeId={storeId}`,
  PROVISIONED_DEVICE_BY_SERIAL_NUMBER: `/v1/model/tenants/{tenantId}/devices/{serNum}`,

  MERCHANDISE_BY_CODE: `/v1/model/tenants/{tenantId}/merchandise?code={merchCode}`,
  MERCHANDISE_SEARCH_BY_CODE: `/v1/model/tenants/{tenantId}/merchandise?sort=code&startAt={merchCode}&endAt={merchCode}z`,
  MERCHANDISE_SEARCH_BY_NAME: `/v1/model/tenants/{tenantId}/merchandise?sort=name&startAt={merchName}&endAt={merchName}z`,
  MERCHANDISE_SORT_BY_NAME: `/v1/model/tenants/{tenantId}/merchandise?sort=name`,
  MERCHANDISE_BY_DEVICEUPC: `/v1/model/tenants/{tenantId}/merchandise?deviceUPC={deviceUPC}`,

  SINGLE_OSA_SENSOR_DATA: `/v1/model/tenants/{tenantId}/devices/{serNum}`,
  OSA_SENSORS_DATA: `/v1/model/tenants/{tenantId}/devices?classId=osa&storeId={storeId}`,
  OSA_DEFAULT_SETTING_DATA: `/v1/model/tenants/{tenantId}/defaultDeviceSettings?type=osa&tenantId={tenantId}`,

  POSITIONS_IN_STORE: `/v1/model/tenants/{tenantId}/positionsInStore`,
  POSITIONS_IN_STORE_SEARCH_BY_NAME: `/v1/model/tenants/{tenantId}/positionsInStore?sort=name&startAt={positionName}&endAt={positionName}z`,

  SINGLE_TV_SENSOR_DATA: `/v1/model/tenants/{tenantId}/devices/{serNum}`,
  TV_SENSORS_DATA: `/v1/model/tenants/{tenantId}/devices?classId=tv&storeId={storeId}`,
  TV_SENSORS_DATA_SORT_BY_POWER: `/v1/model/tenants/{tenantId}/devices?sort=currentState.isOff:desc`,

  ALL_TV_DEVICES_CONFIG: `/v1/device/config/tv/location/{storeId}`,
  SINGLE_TV_DEVICE_CONFIG: `/v1/device/config/tv/{serNum}/`,

  TV_MANUFACTURER_BY_CODE: `/v1/model/tv_manufacturers/{manufacturerCode}`,

  OSA_SENSOR_SETTING: `/v1/model/tenants/{tenantId}/defaultDeviceSettings?tenantId={tenantId}&type=osa`,
  TV_SENSOR_SETTING: `/v1/model/tenants/{tenantId}/defaultDeviceSettings?tenantId={tenantId}&type=tv`,
  SECURITY_SENSOR_SETTING: `/v1/model/tenants/{tenantId}/defaultDeviceSettings?tenantId={tenantId}&type=security`,
  TV_SENSOR_STORE_SETTING: `/v1/model/tenants/{tenantId}/defaultDeviceSettings?storeId={storeId}&type=tv`,

  BUSINESS_HOURS: `/v1/model/tenants/{tenantId}/businessHours?storeId={storeId}`,

  ALARM_SENSORS_DATA: `/v1/model/tenants/{tenantId}/devices?classId=alarm&storeId={storeId}`,
  SINGLE_ALARM_SENSOR_DATA: `/v1/model/tenants/{tenantId}/devices/{serNum}`,
  ALARM_SENSOR_STATUS: `/v1/device/config`,
  PLANOGRAM_STATUS: `/v1/device/config`,
  ALARM_VERTICAL_SENSOR_STATUS: `/v1/device/config`,

  TV_EDID_INFO: `/v1/model/tenants/{tenantId}/tvSensorEdid?deviceUPC={deviceUPC}`,
  // Notification API Endpoints
  GET_LIST_OF_NOTIFICATIONS: `/v1/model/tenants/{tenantId}/notifications?storeId={storeId}`,
  DELETE_SINGLE_NOTIFICATION: `/v1/model/tenants/{tenantId}/notifications/{notificationId}`,
  DELETE_ALL_NOTIFICATIONS: `/v1/model/tenants/{tenantId}/store/notifications/{storeId}`,
  MARK_ALL_AS_READ: `v1/model/tenants/{tenantId}/store/notifications/{storeId}`,
  UPDATE_SINGLE_NOTIFICATION: `/v1/model/tenants/{tenantId}/notifications/{notificationId}`,

  SITE_PLAN_BY_ID: `/v1/model/tenants/{tenantId}/siteplans/{sitePlanId}`,
  STORE_BY_ID: `/v1/model/tenants/{tenantId}/stores/{storeId}`,
};
