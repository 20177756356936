const TOKEN_KEY = 'token_key';
const ENV_KEY = 'env_key';
const STORE_KEY = 'store_key';
const REFRESH_KEY = 'refresh_key';
const NOTIFICATION_REGISTRATION_KEY = 'registration_key';
const USER_KEY = 'user_key';
const TENANT_ID = 'tenantId';
const PROD = 'prod';
const PREVIEW = 'preview';
const REGRESSION = 'regression';

const getToken = (): string | null => sessionStorage.getItem(TOKEN_KEY);

const setToken = (token: string): void => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

const getTenant = (): string | null => sessionStorage.getItem(TENANT_ID);

const setTenant = (tenant: string): void => {
  sessionStorage.setItem(TENANT_ID, tenant);
};

const getEnv = (): string | null => sessionStorage.getItem(ENV_KEY);

const getUrl = (): string => {
  let url = '';

  const env = getEnv();
  const tenantId = getTenant();

  if (!tenantId) {
    throw new Error('No tenant id');
  }
  if(env === PROD || env === PREVIEW || env === REGRESSION) {
    url = `tenants/${tenantId}`;
  } else {
    url = `internal/${env}/tenants/${tenantId}`;
  }

  return url;
}

const setEnv = (env: string): void => {
  sessionStorage.setItem(ENV_KEY, env);
};

const setUser = (userName: string): void => {
  sessionStorage.setItem(USER_KEY, userName);
};

const getUser = (): string | null => {
  return sessionStorage.getItem(USER_KEY);
};

const getStore = (): string | null => sessionStorage.getItem(STORE_KEY);

const setStore = (storeId: string): void => {
  sessionStorage.setItem(STORE_KEY, storeId);
};

const getRefreshInterval = (): string | null => sessionStorage.getItem(REFRESH_KEY);

const setRefreshInterval = (refreshInterval: string): void =>
  sessionStorage.setItem(REFRESH_KEY, refreshInterval);

const setRegistrationToken = (data: string): void =>
  localStorage.setItem(NOTIFICATION_REGISTRATION_KEY, data);

const getRegistrationToken = (): string | null => localStorage.getItem(NOTIFICATION_REGISTRATION_KEY);

export {
  getToken,
  setToken,
  getTenant,
  setTenant,
  getEnv,
  getUrl,
  setEnv,
  getStore,
  setStore,
  setRefreshInterval,
  setRegistrationToken,
  getUser,
  setUser,
  getRegistrationToken,
  getRefreshInterval,
};
