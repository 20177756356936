import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { IconButton, InputAdornment, TextField, makeStyles, createStyles } from '@material-ui/core';
import { SearchContext } from 'modules/app/Context';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      margin: 8,
      width: '90%',
      height: '40px',
    },
    autoinput: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
      },
    },
    input: {
      height: '40px',
      borderRadius: '8px',
    },
  }));

const SearchField = (): JSX.Element => {
  const classes = useStyles();
  const { searchCriteria, updateSearch } = useContext(SearchContext);
  const { formatMessage: i18n } = useIntl();
  const helperText = i18n({ id: 'common.search' });

  const filterOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    updateSearch(e.target.value);
  };

  return (
    <TextField
      placeholder={helperText.toUpperCase()}
      id="search"
      value={searchCriteria}
      onChange={filterOnChange}
      className={classes.textField}
      fullWidth
      margin="normal"
      autoComplete="off"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      InputProps={{
        classes: { input: classes.autoinput },
        className: classes.input,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
