/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import NoDeviceSetup from 'shared/ui/noDeviceSetup';
import Dialog from '@material-ui/core/Dialog';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Typography from '@material-ui/core/Typography';
import { Box, IconButton, createStyles, Divider } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { GatewayContext } from 'modules/app/GatewayContext';
import { isGatewayNotAssigned } from 'modules/gateways/gateway-utils';
import SearchField from 'shared/ui/mobileUI-search-field/index';
import { DataContext } from 'modules/app/DataContext';
import { SearchContext } from 'modules/app/Context';
import styles from './views/list-view.module.scss';
import TVIssuesList from './views/Issues';
import TVsOffAndOn from './views/TVsOffAndOn';
import { fetchTVData, getDevicesData, matchesFilterCriteria, streamLowPowerData, streamTVData, streamTVDevicesData } from '../tvSensor.service';
import { TVCount, TVwithCount, TVSensor, TVwithoutLowPower } from '../model';
import TVConfirmDialog from '../TVConfirmDialog';
import TVSleepModeAlert from '../TVSleepModeAlert';
import { TV_HEART_BEAT_DEFAULT } from '../constant';
import { TVSensorSetting } from '../../devices/model';
import { getDefaultTvSensorSettings } from '../../devices/devices.service';

enum TransitionState { Initial, CommandSent, Processing, Completed }

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      minHeight: '75%',
      maxHeight: '75%',
      minWidth: '85%',
      overflow: 'auto',
    },
    paper: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
    },
    button: {
      lineHeight: '1.75',
      marginTop: '15%',
      width: '55%',
      alignSelf: 'center',
    },
    cancelButton: {
      color: 'black',
    },
    textMessage: {
      textAlign: 'center',
      margin: '10% 0 2% 2%',
    },
    iconDIv: {
      textAlign: 'center',
      margin: '10% 0 2% 2%',
    },
    statusText: {
      textAlign: 'center',
    },
    dialogText: {
      margin: '10%',
      textAlign: 'center',
    },
    action: {
      justifyContent: 'center',
      flex: 'auto',
      marginTop: '25%',
    },
    disabledButton: {
      backgroundColor: '#ECAC00',
      color: '#ECAC00',
      background: '#ECAC00',
    },
    iconImage: {
      color: '#ECAC00',
      fontSize: '50px',
      margin: '10% 0 2% 2%',
    },
    title: {
      fontSize: '18px',
      textTransform: 'uppercase',
      color: 'rgb(115, 115, 115)',
    },
    tvHeaderBox: {
      width: '95%',
    },
    divider: {
      backgroundColor: 'rgb(181, 181, 181)',
    },
    btnContainer: {
      marginBottom: '1em',
      marginTop: '1rem',
    },
  }));

export const defaultTVItemsCount: TVCount = {
  totalCount: 0,
  poweredOffCount: 0,
  poweredOnCount: 0,
  offlineCount: 0,
  TVsWithIssuesCount: 0,
  provisionedCount: 0,
};

export const initialTVSensorSetting: TVSensorSetting = {
  heartbeat: TV_HEART_BEAT_DEFAULT,
  storeId: '',
  type: '',
  onTime: 0,
  offTime: 0,
};

export interface StatusCheckProps {
  currentStatus?: string;
  state: string;
  isOutputHdmiConnected?: boolean;
}

const TVList = (): JSX.Element => {
  const { allSSList, setAllSSList, isLoading, setShowLoader, hideLoader } = useContext(DataContext);
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [defaultTVSensorData, setdefaultTVSensorData] = useState(initialTVSensorSetting);
  const [refresh, setRefresh] = useState(false);

  const { gatewayCurrentStatus } = useContext(GatewayContext);
  const [status, setStatus] = useState(TransitionState.Initial);
  const [isAllOnCommand, setIsAllOnCommand] = useState(false);
  const { formatMessage: i18n } = useIntl();
  const turnAllOffStr = i18n({ id: 'devices.turnAllOff' });
  const turnAllOnStr = i18n({ id: 'devices.turnAllOn' });
  const issuesStr = i18n({ id: 'common.issues' });
  const tvManagersStr = i18n({ id: 'common.tvManagers' });
  const onoffStatusStr = i18n({ id: 'devices.onoffStatus' });
  const [statusCheckMap, setStatusCheckMap] = useState<Map<string, StatusCheckProps>>(new Map());

  const history = useHistory();

  const displayIndex = useRef(sessionStorage.getItem('tvTabIndex'));

  const refreshSensorData = (): void => {
    setRefresh((prevState) => !prevState);
  };

  const updateStatusCheckMap = (updatedStatusCheckMap: Map<string, StatusCheckProps>): void => {
    setStatusCheckMap(new Map(updatedStatusCheckMap));
  };

  const updateWithoutLowPower = (data: TVwithoutLowPower): void => {
    const { count, items } = data;
    setAllSSList((prevState) => ({
      ...prevState,
      tvData: {
        lowPowerModeStatus: prevState.tvData?.lowPowerModeStatus!,
        lowPowerUntilTime: prevState.tvData?.lowPowerUntilTime!,
        count,
        items,
      },
    }));
    if (displayIndex.current != null) {
      setTabIndex(Number(displayIndex.current));
    } else {
      setTabIndex(0);
    }
  };

  const updateWithLowPower = (data: TVwithCount): void => {
    const { count, items, lowPowerModeStatus, lowPowerUntilTime } = data;
    setAllSSList((prevState) => ({
      ...prevState,
      tvData: {
        count,
        items,
        lowPowerModeStatus,
        lowPowerUntilTime,
      },
    }));
    if (displayIndex.current != null) {
      setTabIndex(Number(displayIndex.current));
    } else {
      setTabIndex(0);
    }
  };

  useEffect(() => {
    getDefaultTvSensorSettings().then((values: TVSensorSetting[]) => {
      setdefaultTVSensorData(values[0]);
    });
  }, [refresh, status, tabIndex]);

  useEffect(() => {
    const tvListCount = allSSList?.tvData?.items?.length;
    if (tvListCount !== 0) {
      hideLoader();
    } else {
      setShowLoader(true);
    }

    if (tvListCount === 0) {
      fetchTVData().then(
        ({ count, items, lowPowerModeStatus, lowPowerUntilTime }: TVwithCount) => {
          updateWithLowPower({ count, items, lowPowerModeStatus, lowPowerUntilTime });
          hideLoader();
        },
      );
    } else {
      hideLoader();
    }
  }, [defaultTVSensorData]);

  useEffect(() => {
    let unsubscribeTVData: Function;
    let unsubscribeDevicesData: Function;
    if (defaultTVSensorData.type) {
      unsubscribeTVData = streamTVData({
        next: async (querySnapshot: any) => {
          const list = querySnapshot.docs.map((item: any) => item.data());
          const {
            count, items, lowPowerModeStatus, lowPowerUntilTime,
          }: TVwithCount = await streamLowPowerData(list);
          updateWithLowPower({ count, items, lowPowerModeStatus, lowPowerUntilTime });
        },
      });
      unsubscribeDevicesData = streamTVDevicesData({
        next: async (querySnapshot: any) => {
          const list = querySnapshot.docs.map((item: any) => item.data());
          const { count, items }: TVwithCount = await getDevicesData(list);
          updateWithoutLowPower({ count, items });
        },
      });
    }
    return (): void => {
      if (unsubscribeTVData && unsubscribeDevicesData) {
        unsubscribeTVData();
        unsubscribeDevicesData();
      }
    };
  }, [defaultTVSensorData]);

  const resetTabIndex = (): void => {
    if (!displayIndex.current) {
      sessionStorage.setItem('tvTabIndex', '0');
      setTabIndex(0);
    }
  };

  useEffect(resetTabIndex, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    sessionStorage.setItem('tvTabIndex', String(newValue));
    displayIndex.current = String(newValue);
    if (newValue !== 0) {
      setStatusCheckMap(new Map());
    }
    setTabIndex(newValue);
  };

  const handleOnClickAllOn = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setOpenConfirmDialog(true);
    setIsAllOnCommand(true);
    if (status === TransitionState.Completed) {
      setStatus(TransitionState.Initial);
    }
  };

  const handleOnClickAllOff = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setOpenConfirmDialog(true);
    setIsAllOnCommand(false);
    if (status === TransitionState.Completed) {
      setStatus(TransitionState.Initial);
    }
  };

  const handleClose = (): void => {
    setOpenConfirmDialog(false);
  };

  const handleTVStateChange = (tvCurrentState: TransitionState): void => {
    setStatus(tvCurrentState);
  };

  const back = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    history.push({
      pathname: `/dashboard`,
    });
  };

  const renderTVView = (visibleIndex: number, list: TVSensor[]): JSX.Element => {
    switch (visibleIndex) {
      case 0:
        return (
          <TVsOffAndOn
            list={list}
            refresh={refreshSensorData}
            setStatusCheckMap={setStatusCheckMap}
            updateStatusCheckMap={updateStatusCheckMap}
            statusCheckMap={statusCheckMap}
            defaultTVSetting={defaultTVSensorData}
          />
        );
      case 1:
        return <TVIssuesList list={list} defaultTVSetting={defaultTVSensorData} />;
      default:
        return (<></>);
    }
  };

  const { tvData } = allSSList;
  const { searchCriteria } = useContext(SearchContext);
  let matchedData: TVSensor[] = [];
  if (tvData) {
    const { items } = tvData;
    matchedData = items?.filter((item: TVSensor) => matchesFilterCriteria(item, searchCriteria));
  }

  const TVContent = tvData?.items.length
    ? (
      <>
        {tvData?.lowPowerModeStatus
          ? (
            <div className={styles.sleepModeDiv}>
              <TVSleepModeAlert sleepModeDuration={tvData?.lowPowerUntilTime} />
            </div>
          )
          : null}
        <SearchField />
        <div className={classes.btnContainer}>
          <Button
            variant="contained"
            disabled={
              (status === TransitionState.Processing)
              || isGatewayNotAssigned(gatewayCurrentStatus)
            }
            color="primary"
            style={{ width: '42vw' }}
            onClick={handleOnClickAllOn}
          >
            {turnAllOnStr}
          </Button>
          <Button
            variant="outlined"
            disabled={
              (status === TransitionState.Processing)
              || isGatewayNotAssigned(gatewayCurrentStatus)
            }
            color="primary"
            style={{ width: '42vw' }}
            onClick={handleOnClickAllOff}
          >
            {turnAllOffStr}
          </Button>
        </div>
        <Divider className={classes.divider} />
        <Tabs
          value={tabIndex}
          variant="fullWidth"
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="TV Manager Tabs"
          className={styles['tabs-container']}
        >
          <Tab data-testid="tv-off-tab" label={`${onoffStatusStr} (${tvData.count?.totalCount - tvData.count?.poweredOnCount})`} />
          <Tab data-testid="tv-issues-tab" label={`${issuesStr} (${tvData.count?.TVsWithIssuesCount})`} />
        </Tabs>
        {defaultTVSensorData.type && renderTVView(tabIndex, matchedData)}
        <Dialog classes={{ paper: classes.dialogPaper }} open={openConfirmDialog}>
          <TVConfirmDialog
            isAllOnCommand={isAllOnCommand}
            close={handleClose}
            handleTVStateChange={handleTVStateChange}
          />
        </Dialog>
      </>
    )
    : <NoDeviceSetup />;

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.tvHeaderBox}
      >
        <IconButton onClick={back}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography className={classes.title}>{ tvManagersStr }</Typography>
        <IconButton href="/tv/schedule">
          <ScheduleIcon />
        </IconButton>
      </Box>
      { !isLoading ? TVContent : null }
    </>
  );
};

export default TVList;
