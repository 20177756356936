/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { Canceler } from 'axios';
import httpClient from 'shared/services/http-client';
import { getTenant, getStore, getUrl } from 'common/services/token.service';
import { Device, StagingDevice, OsaSensorSetting, TVSensorSetting, SecuritySetting } from './model';
import { endpoints } from '../../shared/services/endpoints.constants';
import { db } from '../../firebase';

const { CancelToken } = axios;
let cancelFetchAlarmStatusReq: Canceler;
let cancelFetchOSASettingsReq: Canceler;

export const getSingleDevice = async (): Promise<Device[]> => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const result = await db.collection(`${url}/devices`)
    .where('storeId', '==', storeId)
    .orderBy('labelCode')
    .limit(1)
    .get();
  if (!result.empty) {
    return [result.docs[0].data()];
  }
  return [];
};

const getDevices = async (): Promise<Device[]> => {
  // TODO: Access session storage values from getter functions
  const { tenantId, store_key: storeId } = sessionStorage;
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = endpoints.PROVISIONED_DEVICES
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url);
  return response.data;
};

const getDefaultOsaSensorSettings = async (): Promise<OsaSensorSetting[]> => {
  // Cancel any previous network requests
  if (cancelFetchOSASettingsReq) {
    cancelFetchOSASettingsReq();
  }
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = endpoints.OSA_SENSOR_SETTING
    .replace('{tenantId}', tenantId)
    .replace('{tenantId}', tenantId);
  const response = await httpClient.get(url, {
    cancelToken: new CancelToken((canceler: Canceler) => {
      // An executor function receives a cancel function as a parameter
      cancelFetchOSASettingsReq = canceler;
    }),
  });
  return response.data;
};

const getDefaultTvSensorSettings = async (): Promise<TVSensorSetting[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = endpoints.TV_SENSOR_SETTING
    .replace('{tenantId}', tenantId)
    .replace('{tenantId}', tenantId);
  const response = await httpClient.get(url);
  return response.data;
};

const getDefaultSecuritySensorSettings = async (): Promise<SecuritySetting[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = endpoints.SECURITY_SENSOR_SETTING
    .replace('{tenantId}', tenantId)
    .replace('{tenantId}', tenantId);
  const response = await httpClient.get(url);
  return response.data;
};

const getDeviceTenant = async (serialNumber: string): Promise<StagingDevice> => {
  const url = endpoints.ALL_DEVICES_BY_SERIAL_NUMBER
    .replace('{serNum}', serialNumber);
  const response = await httpClient.get(url);
  return response.data.tenantId;
};

const getDeviceBySN = async (serialNumber: string): Promise<Device> => {
  const tenantId = getTenant();
  if (!tenantId || !serialNumber) {
    throw new Error('No Tenant and/or Serial Number.');
  }
  const url = endpoints.PROVISIONED_DEVICE_BY_SERIAL_NUMBER
    .replace('{serNum}', serialNumber)
    .replace('{tenantId}', tenantId);
  const response = await httpClient.get(url);
  return response.data;
};

const saveDeviceBySN = async (serialNumber: string, data: object): Promise<unknown> => {
  const tenantId = getTenant();
  if (!tenantId || !serialNumber) {
    throw new Error('No Tenant and/or Serial Number.');
  }
  // if device exists in staging put else post
  let saveToStaging;
  const stagingData = {
    tenantId,
  };
  const stagingDeviceUrl = endpoints.ALL_DEVICES_BY_SERIAL_NUMBER
    .replace('{serNum}', serialNumber);
  const getStagingResponse = await httpClient.get(stagingDeviceUrl);
  if (getStagingResponse.data) {
    saveToStaging = await httpClient.put(stagingDeviceUrl, stagingData);
  } else {
    saveToStaging = await httpClient.post(stagingDeviceUrl, stagingData);
  }
  // then do same for tenant database
  let saveToTenant;
  const tenantDeviceUrl = endpoints.PROVISIONED_DEVICE_BY_SERIAL_NUMBER
    .replace('{tenantId}', tenantId)
    .replace('{serNum}', serialNumber);
  const getTenantResponse = await httpClient.get(tenantDeviceUrl);
  if (getTenantResponse.data) {
    saveToTenant = await httpClient.put(tenantDeviceUrl, data);
  } else {
    saveToTenant = await httpClient.post(tenantDeviceUrl, data);
  }
  return saveToTenant.status && saveToStaging.status;
};

const getAlarmNodeProvisionStatus = async (): Promise<number> => {
  if (cancelFetchAlarmStatusReq) {
    cancelFetchAlarmStatusReq();
  }
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = endpoints.ALARM_SENSORS_DATA
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url, {
    cancelToken: new CancelToken((canceler: Canceler) => {
      // An executor function receives a cancel function as a parameter
      cancelFetchAlarmStatusReq = canceler;
    }),
  });
  return response.data.length;
};

// onSnapshot updated code of getAlarmNodeProvisionStatus
export const streamAlarmNodeProvisionStatus = (observer: any): Function => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  return db.collection(`${url}/devices`)
    .where('classId', '==', 'alarm')
    .where('storeId', '==', storeId)
    .onSnapshot(observer);
};

export {
  getDevices, getDeviceBySN, saveDeviceBySN, getDeviceTenant, getDefaultOsaSensorSettings,
  getDefaultTvSensorSettings, getDefaultSecuritySensorSettings, getAlarmNodeProvisionStatus,
};
