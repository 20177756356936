import { useEffect, useState } from 'react'

const useLoader = ({ isDataLoaded }) => {
  const [isLoading, setIsLoading] = useState(!isDataLoaded.current);

  useEffect(() => {
    let timeout;
    if (!isDataLoaded.current) {
    // as caching is used, added setTimeout to show loader to avoid displaying old status of devices
    // until the status is calculated in mapSecurityListItem method
      timeout = window.setTimeout(() => {
        setIsLoading(false);
        isDataLoaded.current = true;
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [isDataLoaded]);

  return isLoading;
}

export default useLoader;
