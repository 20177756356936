import httpClient from 'shared/services/http-client';
import { useIntl } from 'react-intl';
import { getStore, getTenant, getUrl } from 'common/services/token.service';
import axios, { AxiosResponse, Canceler } from 'axios';
import { TVManufacturer, TVPowerChangeResponse, TVSensor, TVState, TVwithCount, BusinessHoursModel, TVDayStatus, TVWithLowPowerStatus } from './model';
import { endpoints } from '../../shared/services/endpoints.constants';
import { getHoursFromMinutes, getMinutesSinceMidnight } from './tv-utils';
import { db } from '../../firebase';

const DAYS_ORDER = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
const TWENTYFOUR_HOURS_IN_MINS = 24 * 60;
const EIGHTEEN_HOURS_IN_MINS = 18 * 60;
const { CancelToken } = axios;
let cancelFetchTvDataReq: Canceler;

export const tvPowerStatus = (item: TVSensor): TVState => (item.config
  ? item.config.tvState : TVState.UNKNOWN);

export const isTvOff = (item: TVSensor): boolean => item.config?.tvState === TVState.OFF;

export const isDisconnected = (item: TVSensor): boolean => !item?.isOnline || false;

// export const isRemovedFromPower = (item: TVSensor): boolean =>  FIXME Add when db updated

export const isOuputHDMIUnplugged = (item: TVSensor): boolean =>
  (!(item?.currentState?.isOutputHdmiConnected));

export const isProvisioned = (item: TVSensor): boolean => {
  if (!item.currentState) {
    return false;
  }
  return true;
};

export const isPowerStateUnknown = (item: TVSensor): boolean => ((!item.config)
  ? true : item.config?.tvState === TVState.UNKNOWN);

export const isLowBattery = (item: TVSensor): boolean | undefined => {
  if (item.currentState) {
    return item.currentState.batteryValue <= 3200; // 3.2 volts per tv sensor FW documentation
  }
  return undefined;
};

export const isIssue = (item: TVSensor): boolean | undefined =>
  isDisconnected(item)
  || isPowerStateUnknown(item)
  || !isProvisioned(item)
  || isLowBattery(item)
  || isOuputHDMIUnplugged(item);

/* eslint-disable @typescript-eslint/no-explicit-any */
const processScheduleData = (schedule: any): TVDayStatus[] => {
  const finalData: TVDayStatus[] = [];
  DAYS_ORDER.forEach((day) => {
    if (schedule[day]) {
      const dataRow = {
        day,
        powerOn: schedule[day]?.powerOn || 0,
        powerOff: schedule[day]?.powerOff || 0,
        inactivePowerOn: schedule[day]?.inactivePowerOn || 0,
        inactivePowerOff: schedule[day]?.inactivePowerOff || 0,
      };
      finalData.push(dataRow);
    }
  });
  return finalData;
};

const getPrevPowerRule = (scheduleData: TVDayStatus[], index: number): any => {
  if (index === 0) {
    return scheduleData[scheduleData.length - 1];
  }
  return scheduleData[index - 1];
};

const getNextPowerRule = (scheduleData: TVDayStatus[], index: number): any => {
  if (index === scheduleData.length - 1) {
    return scheduleData[0];
  }
  return scheduleData[index + 1];
};

const getScheduleActiveDayArray = (scheduleArray: TVDayStatus[]): number =>
  scheduleArray.filter((x) => x.powerOn !== 0).length;

// eslint-disable-next-line
const getInActiveDayLowPowerStatus = (scheduleArray: TVDayStatus[], index: number): any => {
  const lowPowerStatus = {
    lowPowerModeStatus: false,
    lowPowerUntilTime: '',
  };
  let nextIndex = (index + 1) % scheduleArray.length;
  let nextPowerRule = scheduleArray[nextIndex];
  while (nextIndex < scheduleArray.length) {
    if (nextPowerRule.day === scheduleArray[nextIndex].day) {
      if (nextPowerRule?.powerOn || nextPowerRule?.powerOff) {
        lowPowerStatus.lowPowerModeStatus = true;
        lowPowerStatus.lowPowerUntilTime = `until ${nextPowerRule.day.charAt(0).toUpperCase() + nextPowerRule.day.slice(1)} ${getHoursFromMinutes(nextPowerRule.powerOn)}`;
        return lowPowerStatus;
      }
      nextIndex = (nextIndex + 1) % scheduleArray.length;
      nextPowerRule = scheduleArray[nextIndex];
    } else {
      nextPowerRule = scheduleArray[nextIndex];
      nextIndex += 1;
    }
  }
  return lowPowerStatus;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getLowPowerStatus = (sensorSetting: any): any => {
  let schedule = {};

  const lowPowerStatus = {
    lowPowerModeStatus: false,
    lowPowerUntilTime: '',
  };

  if (sensorSetting.length > 0 && sensorSetting[0].schedule) {
    schedule = sensorSetting[0].schedule;
    const scheduleArray = processScheduleData(schedule);

    const date = new Date();
    const currentTimeSinceMidnight = getMinutesSinceMidnight(date);

    for (let index = 0; index < scheduleArray.length; index += 1) {
      const { day } = scheduleArray[index];
      const currentDay = date.getDay();

      if (DAYS_ORDER[currentDay] === day) {
        const previousPowerRule = getPrevPowerRule(scheduleArray, index);
        const nextPowerRule = getNextPowerRule(scheduleArray, index);

        const currentSchedule = scheduleArray[index];
        let minutesBetweenOffAndOn = 0;
        let minutesBeforeMidnight = 0;
        let minutesUntilMidnight = 0;

        switch (true) {
          case (getScheduleActiveDayArray(scheduleArray) === 0):
            lowPowerStatus.lowPowerModeStatus = true;
            lowPowerStatus.lowPowerUntilTime = `until further notice`;
            return lowPowerStatus;

          case (currentSchedule?.powerOn && currentTimeSinceMidnight < currentSchedule.powerOn):
            minutesBeforeMidnight = TWENTYFOUR_HOURS_IN_MINS - previousPowerRule.powerOff;
            minutesBetweenOffAndOn = minutesBeforeMidnight + currentSchedule.powerOn;
            lowPowerStatus.lowPowerModeStatus = minutesBetweenOffAndOn > EIGHTEEN_HOURS_IN_MINS;
            lowPowerStatus.lowPowerUntilTime = `until ${currentSchedule.day.charAt(0).toUpperCase() + currentSchedule.day.slice(1)} ${getHoursFromMinutes(currentSchedule.powerOn)}`;
            return lowPowerStatus;

          case (currentSchedule?.powerOff && currentTimeSinceMidnight > currentSchedule.powerOff):
            minutesUntilMidnight = TWENTYFOUR_HOURS_IN_MINS - currentSchedule.powerOff;
            minutesBetweenOffAndOn = minutesUntilMidnight + nextPowerRule.powerOn;

            if (nextPowerRule?.inactivePowerOn || nextPowerRule?.inactivePowerOff) {
              return getInActiveDayLowPowerStatus(scheduleArray, index);
            }
            if (minutesBetweenOffAndOn > EIGHTEEN_HOURS_IN_MINS) {
              lowPowerStatus.lowPowerModeStatus = minutesBetweenOffAndOn > EIGHTEEN_HOURS_IN_MINS;
              lowPowerStatus.lowPowerUntilTime = `until ${nextPowerRule.day.charAt(0).toUpperCase() + nextPowerRule.day.slice(1)} ${getHoursFromMinutes(nextPowerRule.powerOn)}`;
              return lowPowerStatus;
            }
            return lowPowerStatus;

          case (currentSchedule?.inactivePowerOn && currentSchedule?.inactivePowerOn > 0):
            return getInActiveDayLowPowerStatus(scheduleArray, index);

          default:
            return lowPowerStatus;
        }
      }
    }
  }
  return lowPowerStatus;
};

export const fetchTVData = async (): Promise<TVwithCount> => {
  // Cancel any previous network requests
  if (cancelFetchTvDataReq) {
    cancelFetchTvDataReq();
  }

  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store');
  }
  const sensorSettingUrl = endpoints.TV_SENSOR_STORE_SETTING
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const sensorSettingresponse = await httpClient.get(sensorSettingUrl, {
    cancelToken: new CancelToken((canceler: Canceler) => {
      // An executor function receives a cancel function as a parameter
      cancelFetchTvDataReq = canceler;
    }),
  });

  const lowPowerStatus = getLowPowerStatus(sensorSettingresponse.data);
  const { lowPowerModeStatus, lowPowerUntilTime } = lowPowerStatus;

  const url = endpoints.TV_SENSORS_DATA
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url, {
    cancelToken: new CancelToken((canceler: Canceler) => {
      // An executor function receives a cancel function as a parameter
      cancelFetchTvDataReq = canceler;
    }),
  });
  const items = response.data;
  const count = {
    totalCount: items.length,
    offlineCount: 0,
    poweredOffCount: 0,
    poweredOnCount: 0,
    TVsWithIssuesCount: 0,
    provisionedCount: 0,
  };
  const filteredItems = (items && items.length > 0)
    ? items.filter((item: TVSensor) => !(item.isDeleted)) : [];
  count.totalCount = filteredItems.length;
  filteredItems.forEach((item: TVSensor) => {
    if (
      (isDisconnected(item))
      || (isLowBattery(item))
      || (!isProvisioned(item))
      || (tvPowerStatus(item) === TVState.UNKNOWN)

      // || isRemovedFromPower(item)
      || isOuputHDMIUnplugged(item)
      // TODO || any other flagged issue
    ) {
      count.TVsWithIssuesCount += 1;
    }
    if (isDisconnected(item)) {
      count.offlineCount += 1;
    }
    if (!isOuputHDMIUnplugged(item) && tvPowerStatus(item) === TVState.OFF) {
      count.poweredOffCount += 1;
    }
    if (!isOuputHDMIUnplugged(item) && tvPowerStatus(item) === TVState.ON) {
      count.poweredOnCount += 1;
    }
    if (isProvisioned(item)) {
      count.provisionedCount += 1;
    }
    // TODO add other issues
  });
  return {
    count,
    lowPowerModeStatus,
    lowPowerUntilTime,
    items: filteredItems.map((item: TVSensor) => ({
      ...item,
    })),
  };
};

// onSnapshot code of fetchTVData
export const streamTVData = (observer: any): Function => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  return db.collection(`${url}/defaultDeviceSettings`)
    .where('type', '==', 'tv')
    .where('storeId', '==', storeId)
    .onSnapshot(observer);
};

// onSnapshot code of updated devices for tv
export const streamTVDevicesData = (observer: any): Function => {
  const storeId = getStore();
  const tenantId = getTenant();
  const url = getUrl();
  if (!storeId || !tenantId) {
    throw new Error('No tenant/store id');
  }
  return db.collection(`${url}/devices`)
    .where('classId', '==', 'tv')
    .where('storeId', '==', storeId)
    .onSnapshot(observer);
};

export const streamLowPowerData = async (list: any):
Promise<any> => {
  const tenantId = getTenant();
  const storeId = getStore();

  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }

  const lowPowerStatus = getLowPowerStatus(list);
  const { lowPowerModeStatus, lowPowerUntilTime } = lowPowerStatus;

  const url = endpoints.TV_SENSORS_DATA
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url, {
    cancelToken: new CancelToken((canceler: Canceler) => {
      // An executor function receives a cancel function as a parameter
      cancelFetchTvDataReq = canceler;
    }),
  });
  const items = response.data;
  const count = {
    totalCount: items.length,
    offlineCount: 0,
    poweredOffCount: 0,
    poweredOnCount: 0,
    TVsWithIssuesCount: 0,
    provisionedCount: 0,
  };
  const filteredItems = (items && items.length > 0)
    ? items.filter((item: TVSensor) => !(item.isDeleted)) : [];
  count.totalCount = filteredItems.length;
  if (filteredItems.length > 0) {
    filteredItems.forEach((item: TVSensor) => {
      if (
        (isDisconnected(item))
        || (isLowBattery(item))
        || (!isProvisioned(item))
        || (tvPowerStatus(item) === TVState.UNKNOWN)

        // || isRemovedFromPower(item)
        || isOuputHDMIUnplugged(item)
        // TODO || any other flagged issue
      ) {
        count.TVsWithIssuesCount += 1;
      }
      if (isDisconnected(item)) {
        count.offlineCount += 1;
      }
      if (!isOuputHDMIUnplugged(item) && tvPowerStatus(item) === TVState.OFF) {
        count.poweredOffCount += 1;
      }
      if (!isOuputHDMIUnplugged(item) && tvPowerStatus(item) === TVState.ON) {
        count.poweredOnCount += 1;
      }
      if (isProvisioned(item)) {
        count.provisionedCount += 1;
      }
      // TODO add other issues
    });
  }
  return {
    count,
    lowPowerModeStatus,
    lowPowerUntilTime,
    items: filteredItems.map((item: TVSensor) => ({
      ...item,
    })),
  };
};

export const getDevicesData = (items: any): any => {
  const lowPowerStatus = getLowPowerStatus(items);
  const { lowPowerModeStatus, lowPowerUntilTime } = lowPowerStatus;
  const count = {
    totalCount: items.length,
    offlineCount: 0,
    poweredOffCount: 0,
    poweredOnCount: 0,
    TVsWithIssuesCount: 0,
    provisionedCount: 0,
  };
  const filteredItems = (items && items.length > 0)
    ? items.filter((item: TVSensor) => !(item.isDeleted)) : [];
  count.totalCount = filteredItems.length;
  if (filteredItems.length > 0) {
    filteredItems.forEach((item: TVSensor) => {
      if (
        (isDisconnected(item))
        || (isLowBattery(item))
        || (!isProvisioned(item))
        || (tvPowerStatus(item) === TVState.UNKNOWN)

        // || isRemovedFromPower(item)
        || isOuputHDMIUnplugged(item)
        // TODO || any other flagged issue
      ) {
        count.TVsWithIssuesCount += 1;
      }
      if (isDisconnected(item)) {
        count.offlineCount += 1;
      }
      if (!isOuputHDMIUnplugged(item) && tvPowerStatus(item) === TVState.OFF) {
        count.poweredOffCount += 1;
      }
      if (!isOuputHDMIUnplugged(item) && tvPowerStatus(item) === TVState.ON) {
        count.poweredOnCount += 1;
      }
      if (isProvisioned(item)) {
        count.provisionedCount += 1;
      }
      // TODO add other issues
    });
  }
  return {
    count,
    lowPowerModeStatus,
    lowPowerUntilTime,
    items: filteredItems.map((item: TVSensor) => ({
      ...item,
    })),
  };
};

export const fetchTVDataSortedByPower = async (): Promise<TVSensor[]> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No Tenant.');
  }
  const url = endpoints.TV_SENSORS_DATA_SORT_BY_POWER
    .replace('{tenantId}', tenantId);
  const response = await httpClient.get(url);
  const items = response.data;
  return items;
};

export const getTimeSinceIssue = (timeInMs: number | undefined): string => {
  if (timeInMs) {
    const timeSinceOff = Date.now() - timeInMs;
    const sec = timeSinceOff / 1000;
    if (sec >= (3600 * 24 * 14)) {
      return `${Math.round(sec / (3600 * 24 * 7))} weeks`;
    } if (sec >= (3600 * 48)) {
      return `${Math.round(sec / (3600 * 24))} days`;
    } if (sec >= (3600 * 2)) {
      return `${Math.round(sec / 3600)} hours`;
    } if (sec >= 120) {
      return `${Math.round(sec / 60)} minutes`;
    } if (sec >= 10) {
      return `${Math.round(sec)} seconds`;
    }
    return `< 10 seconds`;
  }
  return '';
};

export const useIntlOffline = (): string => {
  const { formatMessage: i18n } = useIntl();
  return i18n({ id: 'common.offline' });
};

export const powerOnTV = async (serialNumber: string): Promise<AxiosResponse> => {
  const url = endpoints.SINGLE_TV_DEVICE_CONFIG
    .replace('{serNum}', serialNumber);
  const data = { isTvPoweredOn: true };
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
  };
  const response = await httpClient.post(url, data, updatedConfig);
  return response;
};

export const powerOffTV = async (serialNumber: string): Promise<AxiosResponse> => {
  const url = endpoints.SINGLE_TV_DEVICE_CONFIG
    .replace('{serNum}', serialNumber);
  const data = { isTvPoweredOn: false };
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
  };
  const response = await httpClient.post(url, data, updatedConfig);
  return response;
};

export const powerOnAllTVs = async (): Promise<TVPowerChangeResponse> => {
  const storeId = getStore() || '';
  const url = endpoints.ALL_TV_DEVICES_CONFIG
    .replace('{storeId}', storeId);
  const data = { isTvPoweredOn: true };
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
  };
  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const powerOffAllTVs = async (): Promise<TVPowerChangeResponse> => {
  const storeId = getStore() || '';
  const url = endpoints.ALL_TV_DEVICES_CONFIG
    .replace('{storeId}', storeId);
  const data = { isTvPoweredOn: false };
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
  };
  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const fetchTVDevice = async (serialNumber: string): Promise<TVWithLowPowerStatus> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }

  const sensorSettingUrl = endpoints.TV_SENSOR_STORE_SETTING
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const sensorSettingresponse = await httpClient.get(sensorSettingUrl, {
    cancelToken: new CancelToken((canceler: Canceler) => {
      // An executor function receives a cancel function as a parameter
      cancelFetchTvDataReq = canceler;
    }),
  });

  const lowPowerStatus = getLowPowerStatus(sensorSettingresponse.data);
  const { lowPowerModeStatus, lowPowerUntilTime } = lowPowerStatus;
  if (!tenantId || !serialNumber) {
    throw new Error('No Tenant and/or Serial Number');
  }
  const url = endpoints.SINGLE_TV_SENSOR_DATA
    .replace('{tenantId}', tenantId)
    .replace('{serNum}', serialNumber);
  const response = await httpClient.get(url);
  return {
    lowPowerModeStatus,
    lowPowerUntilTime,
    tvData: response.data,
  };
};

export const streamTVSensorData = (serialNumber: string, observer: any): Function => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId || !serialNumber) {
    throw new Error('No tenant/store id/serial number');
  }

  return db.collection(`${url}/devices`)
    .doc(serialNumber)
    .onSnapshot(observer);
};

export const matchesFilterCriteria = (item: TVSensor, searchData: string): boolean => {
  const { merchandise: { name: merchandiseName, brand: brandName = '' } } = item;
  if (item.positionInStore?.name) {
    return merchandiseName.toUpperCase().includes(searchData.toUpperCase())
      || brandName.toUpperCase().includes(searchData.toUpperCase())
      || item.positionInStore?.name.toUpperCase().includes(searchData.toUpperCase());
  }
  return merchandiseName.toUpperCase().includes(searchData.toUpperCase())
    || brandName.toUpperCase().includes(searchData.toUpperCase());
};

export const getTVManufacturerName = async (manufacturerCode: string): Promise<TVManufacturer> => {
  const url = endpoints.TV_MANUFACTURER_BY_CODE
    .replace('{manufacturerCode}', manufacturerCode);
  const response = await httpClient.get(url);
  return response.data;
};

export const refreshEdidData = async (serialNumber: string): Promise<void> => {
  const url = endpoints.SINGLE_TV_DEVICE_CONFIG
    .replace('{serNum}', serialNumber);
  const data = { refreshEdidData: true };
  await httpClient.post(url, data);
};

export const deleteTVSensor = async (serialNumber: string): Promise<string> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant/store id');
  }
  const url = endpoints.SINGLE_TV_SENSOR_DATA
    .replace('{tenantId}', tenantId)
    .replace('{serNum}', serialNumber);
  const response = await httpClient.delete(url);
  return response.data;
};

export const fetchBusinessHours = async (): Promise<BusinessHoursModel[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  const url = endpoints.BUSINESS_HOURS
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url);
  return response.data;
};

export const refreshTvState = async (serialNumber: string): Promise<void> => {
  const url = endpoints.SINGLE_TV_DEVICE_CONFIG
    .replace('{serNum}', serialNumber);
  const data = { refreshTvState: true };
  const tenant = getTenant();
  const updatedConfig = {
    headers: {
      tenantid: tenant,
    },
  };
  await httpClient.post(url, data, updatedConfig);
};
