import moment from 'moment';
import { StatusCheckProps } from './list';
import { TVSensor } from './model';
import { TV_BATTERY_THRESHOLD, TV_BATTERY_LEVEL } from './constant';
import { TVListViewItemData } from './list/views/list-view';

export const DEFAULT_LABEL_CODE = '0000';

export const getMerchandiseBrandWithNameForTV = (item: TVSensor): string =>
  (item.merchandise?.brand ? `${item.merchandise.brand} ${item.merchandise.name}` : item.merchandise.name || '');

export const UNKNOWN_STATES = ['UNKNOWN', 'TURNING_ON', 'TURNING_OFF'];
export const UNKNOWN = 'UNKNOWN';
export const FAILED = 'failed';
export const SUCCESS = 'success';
export const IN_PROGRESS = 'inprogress';
export const ERROR = 'error';

interface ProcessTvItemProps {
  isUpdated: boolean;
  updatedStatusCheckMap: Map<string, StatusCheckProps>;
}

export const processTvItem = (currentItem: TVListViewItemData,
  statusCheckMap: Map<string, StatusCheckProps>): ProcessTvItemProps => {
  const newstatusCheckMap = new Map(statusCheckMap);
  let changed = false;
  if (newstatusCheckMap.has('all') || newstatusCheckMap.has(currentItem.TVData.labelCode)) {
    const itemStatus = newstatusCheckMap.get(currentItem.TVData.labelCode);
    let newState = '';
    let newCurrentStatus = '';
    let newIsOutputHdmiConnected;
    if (itemStatus) {
      const { currentStatus, isOutputHdmiConnected } = itemStatus;
      if (currentStatus !== (currentItem.status?.text || UNKNOWN)) {
        // tv state changed
        if (isOutputHdmiConnected !== (!!currentItem?.TVData.currentState.isOutputHdmiConnected)) {
          // cable connected/disconnected status changed
          newIsOutputHdmiConnected = !!currentItem?.TVData.currentState.isOutputHdmiConnected;
        } else {
          newIsOutputHdmiConnected = isOutputHdmiConnected;
        }
        newCurrentStatus = currentItem.status?.text || UNKNOWN;
        changed = true;
      } else if (isOutputHdmiConnected
        !== (!!currentItem?.TVData.currentState.isOutputHdmiConnected)) {
        // cable connected status changed
        newIsOutputHdmiConnected = !!currentItem?.TVData.currentState.isOutputHdmiConnected;
        newCurrentStatus = currentStatus;
        changed = true;
      }
      if (newIsOutputHdmiConnected) {
        newState = UNKNOWN_STATES.includes(newCurrentStatus) ? FAILED : SUCCESS;
      } else {
        newState = ERROR;
      }
    } else {
      // initial adding to status check list
      newCurrentStatus = currentItem.status?.text || UNKNOWN;
      newIsOutputHdmiConnected = !!currentItem?.TVData.currentState.isOutputHdmiConnected;

      changed = true;
      if (newIsOutputHdmiConnected || !newIsOutputHdmiConnected) {
        newState = currentItem.status?.text || 'refresh';
      }
    }
    if (changed) {
      newstatusCheckMap.set(currentItem.TVData.labelCode, {
        state: newState,
        currentStatus: newCurrentStatus,
        isOutputHdmiConnected: newIsOutputHdmiConnected,
      });
    }
  }
  return { isUpdated: changed, updatedStatusCheckMap: newstatusCheckMap };
};

export const getMinutesSinceMidnight = (time: Date): number => {
  const minutes = (60 * time.getHours()) + time.getMinutes();
  return minutes;
};

export const getHoursFromMinutes = (minutesSinceMidnight: number | undefined): string => {
  const militaryTimeMomentFormat = 'hh:mmA';
  if (minutesSinceMidnight) {
    const hours = Math.floor(minutesSinceMidnight / 60);
    const minutes = minutesSinceMidnight % 60;
    const sleepModeUntil = moment(`${hours}:${minutes}`,
      militaryTimeMomentFormat).format(militaryTimeMomentFormat);
    return sleepModeUntil;
  }
  return '';
};

export const getBatteryLevel = (batteryValue: number): string => {
  let batteryLevel = TV_BATTERY_LEVEL.ok;

  if (batteryValue <= TV_BATTERY_THRESHOLD.critical) {
    batteryLevel = TV_BATTERY_LEVEL.critical;
  } else if (batteryValue >= TV_BATTERY_THRESHOLD.low.min
    && batteryValue <= TV_BATTERY_THRESHOLD.low.max) {
    batteryLevel = TV_BATTERY_LEVEL.low;
  } else if (batteryValue >= TV_BATTERY_THRESHOLD.ok) {
    batteryLevel = TV_BATTERY_LEVEL.ok;
  }

  return batteryLevel;
};
