import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Paper, Box, Divider } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { DescriptionText, ItemStatus, ListItemErrorStatus } from './model';

interface ListItemProps {
  description: DescriptionText[];
  status: ItemStatus;
  pathname?: string;
}

const getStatusColor = (errorStatus: ListItemErrorStatus | undefined, theme: Theme): string => {
  switch (errorStatus) {
    case ListItemErrorStatus.Danger:
      return theme.palette.error.main;
    case ListItemErrorStatus.warning:
      return theme.palette.warning.main;
    default:
      return '';
  }
};

const useStyle = makeStyles((theme: Theme) => createStyles({
  status: ({ status }: ListItemProps) => ({
    color: getStatusColor(status.errorStatus, theme),
  }),
  textBold: {
    fontWeight: 'bold',
    color: 'rgb(123,123,123)',
    flex: '1',
  },
  textNormal: {
    color: 'rgb(123,123,123)',
    flex: '1',
  },
  fontNormal: {
    fontWeight: 100,
  },
  paper: {
    marginBottom: '1.5rem',
    backgroundColor: '#f1f1f1',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.7rem 1rem',
    justifyContent: 'space-between',
  },
  divider: {
    margin: 0,
    backgroundColor: 'rgb(181, 181, 181)',
  },
  tvStatus: {
    color: 'rgb(135, 135, 135)',
    textTransform: 'capitalize',
    marginRight: 5,
  },
  tvEmptyStatus: {
    color: '#ff0000',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginRight: 5,
  },
  tvFullStatus: {
    color: 'rgb(241, 161, 42)',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    marginRight: 5,
  },
}));

const ListItem = (props: ListItemProps): JSX.Element => {
  const styles = useStyle(props);
  const history = useHistory();
  const { description, status, pathname } = props;
  const { formatMessage: i18n } = useIntl();
  const emptyStr = i18n({ id: 'devices.empty' });

  const getStatusStyle = (currentStatus: string): string => {
    if (currentStatus.includes(emptyStr)) {
      return `${styles.tvEmptyStatus}`;
    }

    if (currentStatus.includes('FULL') || currentStatus.includes('Full')) {
      return styles.tvFullStatus;
    }
    return styles.tvStatus;
  };

  // TODO: refactor to avoid checking for closing bracket
  const getStatusWithTime = (deviceStatus: string): JSX.Element | string => {
    if (deviceStatus.includes(emptyStr)) {
      const closingBracketIndex = deviceStatus.indexOf(')');
      if (closingBracketIndex) {
        const onlyTime = deviceStatus.substring(0, closingBracketIndex + 1);
        const onlyText = deviceStatus.substring(closingBracketIndex + 1);
        return (
          <>
            <span className={styles.fontNormal}>{onlyTime}</span>
            {onlyText.toUpperCase()}
          </>
        );
      }
    } else if (deviceStatus.includes('FULL') || deviceStatus.includes('Full')) {
      return deviceStatus.toUpperCase();
    }
    return deviceStatus;
  };

  const handleRedirect = (): void => {
    history.push(pathname || '/osa');
  };

  return (
    <Paper elevation={3} className={styles.paper}>
      {description.map((item: DescriptionText) => (
        <Fragment key={item.text}>
          <Box className={styles.box}>
            <Typography
              key={item.text}
              variant="body1"
              noWrap
              className={item.bold ? styles.textBold : styles.textNormal}
            >
              {item.text}
            </Typography>
            <div>
              {item.bold
                ? (
                  <div role="button" tabIndex={0} onClick={handleRedirect} onKeyDown={handleRedirect} color="inherit">
                    <KeyboardArrowRightIcon />
                  </div>
                )
                : (
                  <>
                    {status?.desc?.includes('FULL') || status?.desc?.includes('Full') ? (
                      <Typography align="center" className={status && status.desc && getStatusStyle(status.desc)}>
                        {status && status.desc && getStatusWithTime(status.desc)}
                      </Typography>
                    ) : (
                      <Typography align="center" className={status && status.text && getStatusStyle(status.text)}>
                        {status && status.text && getStatusWithTime(status.text)}
                      </Typography>
                    )}
                  </>
                )}
            </div>
          </Box>
          {item.bold && <Divider className={styles.divider} />}
        </Fragment>
      ))}
    </Paper>
  );
};

export default ListItem;
