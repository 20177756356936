import React, { useEffect, useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { GatewayContext } from 'modules/app/GatewayContext';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { getTimerValue } from './panicButtons-util';

interface PanicButtonBannerProps {
  panicStopTime: number;
}

const useStyle = makeStyles({
  panicContainer: {
    backgroundColor: 'rgba(205,55,15,255)',
    marginTop: '5px',
    height: '50px',
    color: '#fff',
    fontWeight: 500,
  },
  parentContainer: {
    width: '85%',
    lineHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
  },
  timerSection: {
    marginRight: '5%',
  },
  timerIcon: {
    verticalAlign: 'middle',
    marginRight: '8px',
  },
  timerValue: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const PanicButtonBanner = ({ panicStopTime }: PanicButtonBannerProps): JSX.Element => {
  const [timerValue, setTimerValue] = useState('');
  const { setIsPanicSituation } = useContext(GatewayContext);
  const styles = useStyle();
  const { formatMessage: i18n } = useIntl();
  const panicLockdownStr = i18n({ id: 'devices.panicLockdown' });

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const timeRemaining = getTimerValue(panicStopTime);
      if (timeRemaining === '0') {
        setIsPanicSituation(false);
        clearInterval(timerInterval);
      }
      setTimerValue(timeRemaining);
    }, 500);
    return (): void => {
      clearInterval(timerInterval);
    };
  }, [panicStopTime]);

  return (
    <div className={styles.panicContainer}>
      <div className={styles.parentContainer}>
        <div>
          {panicLockdownStr.toUpperCase()}
        </div>
        <div className={styles.timerSection}>
          <AccessTimeIcon className={styles.timerIcon} />
          <span className={styles.timerValue}>{timerValue}</span>
        </div>
      </div>
    </div>
  );
};

export default PanicButtonBanner;
