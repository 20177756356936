// Battery level thresholds
export const OSA_BATTERY_THRESHOLD = {
  low: {
    min: 85,
    max: 120,
  },
  ok: {
    min: 121,
    max: 255,
  },
  critical: 84,
};

export const OSA_BATTERY_LEVEL = {
  ok: 'ok',
  low: 'Low',
  critical: 'Critical',
  unknown: 'Unknown',
};

export const HEART_BEAT_DEFAULT = 36000;

export const OSA_FIXTURE_TYPE = {
  coil: 'coil',
  standard: 'standard',
  pusher: 'pusher',
};

export const OSA = 'osa';
