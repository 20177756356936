import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionText } from 'shared/ui/device-list/list-item/model';
import Container from '@material-ui/core/Container';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Button } from '@material-ui/core';
import { getMerchandiseBrandWithNameForTV, UNKNOWN_STATES, UNKNOWN, IN_PROGRESS, processTvItem } from 'modules/tv-sensors/tv-utils';
import { TVSensor, TVSensorSort, TVState } from '../../model';
import { powerOffTV,
  powerOnTV,
  tvPowerStatus,
  matchesFilterCriteria,
  refreshTvState,
  isProvisioned,
  isOuputHDMIUnplugged,
  isDisconnected, isPowerStateUnknown, isLowBattery } from '../../tvSensor.service';
import { barcodeToSNFormat } from '../../../../shared/services/utils';
import styles from './list-view.module.scss';
import { TVPowerListViewProps } from './model';
import PowerListItem from './power-list-item';
import { SearchContext } from '../../../app/Context';
import { TVListViewItemData } from './list-view';

interface RefreshStateModel {
  [key: string]: boolean;
}

const compare = (a: TVSensorSort, b: TVSensorSort): number => a.order - b.order;
const ALL = 'all';
const TVsOffAndOn = ({ list,
  refresh,
  statusCheckMap,
  setStatusCheckMap }: TVPowerListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const [intervalIds] = useState<number[]>([]);
  const [timeoutIds] = useState<number[]>([]);
  const tvRefreshInitialState: RefreshStateModel = {};
  const [tvRefreshState, setTVRefreshState] = useState(tvRefreshInitialState);
  const { formatMessage: i18n } = useIntl();
  const pleaseWaitStr = i18n({ id: 'common.pleaseWait' });
  const offlineStr = i18n({ id: 'common.offline' });
  const installPendingStr = i18n({ id: 'devices.installPending' });
  const lowBatteryStr = i18n({ id: 'common.lowBattery' });
  const powerStateUnknownStr = i18n({ id: 'devices.powerStateUnknown' });
  const noHDMIConnectionStr = i18n({ id: 'devices.hdmiUnknown' });
  const labels = {
    tvOn: i18n({ id: 'devices.on' }),
    tvOff: i18n({ id: 'devices.off' }),
  };
  const [sortedFilteredList, setSortedFilteredList] = useState<TVListViewItemData[]>([]);
  const filteredList = (items: TVSensor[]): TVSensorSort[] =>
    items.filter((item: TVSensor) => matchesFilterCriteria(item, searchCriteria))
      .map((currentItem) => {
        let order = 10;
        if (!isProvisioned(currentItem)) {
          order = 1;
        } else if (isDisconnected(currentItem)) {
          order = 2;
        } else if (isOuputHDMIUnplugged(currentItem)) {
          order = 3;
        } else if (isPowerStateUnknown(currentItem)) {
          order = 4;
        } else if (UNKNOWN_STATES.includes(currentItem.config?.tvState || UNKNOWN)) {
          order = 5;
        } else if (isLowBattery(currentItem)) {
          order = 6;
        } else if (tvPowerStatus(currentItem) === TVState.OFF) {
          order = 7;
        } else if (tvPowerStatus(currentItem) === TVState.ON) {
          order = 8;
        } else if (!UNKNOWN_STATES.includes(currentItem.config?.tvState || UNKNOWN)) {
          order = 9;
        }
        return { ...currentItem, order };
      }).sort((a, b) => compare(a, b));

  const filteredListSort = (items: TVSensorSort[]): TVListViewItemData[] =>
    items.map((item) => {
      let statusText;
      if (!isProvisioned(item)) {
        statusText = installPendingStr;
      } else if (isDisconnected(item)) {
        statusText = offlineStr;
      } else if (isOuputHDMIUnplugged(item)) {
        statusText = noHDMIConnectionStr;
      } else if (isPowerStateUnknown(item)) {
        statusText = powerStateUnknownStr;
      } else if (UNKNOWN_STATES.includes(item.config?.tvState || UNKNOWN)) {
        statusText = 'refresh';
      } else if (isLowBattery(item)) {
        statusText = lowBatteryStr;
      } else if (tvPowerStatus(item) === TVState.OFF) {
        statusText = labels.tvOff;
      } else if (tvPowerStatus(item) === TVState.ON) {
        statusText = labels.tvOn;
      } else if (!UNKNOWN_STATES.includes(item.config?.tvState || UNKNOWN)) {
        statusText = IN_PROGRESS;
      }
      const status = {
        text: statusText,
      };
      return {
        TVData: item,
        status,
      };
    });

  const [open, setOpen] = useState(false);
  const [waitingForResponse] = useState(false);
  const [tvErrorMessage, setTvErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const checkStateChanges = (name: string): void => {
    const newstatusCheckMap = new Map(statusCheckMap);
    const orderList = filteredList(list);

    const currentItem = orderList.find((item) => item.labelCode === name);
    if (currentItem) {
      let newState = '';
      const newCurrentStatus = currentItem.config?.tvState || UNKNOWN;
      const newIsOutputHdmiConnected = !!currentItem?.currentState.isOutputHdmiConnected;
      if (newIsOutputHdmiConnected || !newIsOutputHdmiConnected) {
        const currentStateData = filteredListSort([currentItem]);
        newState = currentStateData[0]?.status?.text || 'refresh';
      }
      newstatusCheckMap.set(name, {
        state: newState,
        currentStatus: newCurrentStatus,
        isOutputHdmiConnected: newIsOutputHdmiConnected,
      });
      const refreshIntervalId = window.setInterval(() => {
        refresh();
      }, 10000);
      intervalIds.push(refreshIntervalId);
      const timeoutId = window.setTimeout(() => setStatusCheckMap((currentStatusCheckMap) => {
        const updatedStatusCheckMap = new Map(currentStatusCheckMap);
        if (refreshIntervalId) {
          window.clearInterval(refreshIntervalId);
        }
        if (updatedStatusCheckMap.has(ALL)) {
          return updatedStatusCheckMap;
        }
        const existingItem = updatedStatusCheckMap.get(name);
        if (existingItem) {
          const { state,
            isOutputHdmiConnected,
            currentStatus } = existingItem;
          if (!UNKNOWN_STATES.includes(state || UNKNOWN)) {
            const currentStateData = filteredListSort([currentItem]);
            updatedStatusCheckMap.set(name, {
              state: currentStateData[0]?.status?.text || 'refresh',
              currentStatus,
              isOutputHdmiConnected: !!isOutputHdmiConnected,
            });
          }
        }
        return updatedStatusCheckMap;
      }), 30000);
      timeoutIds.push(timeoutId);
      setStatusCheckMap(new Map(newstatusCheckMap));
    }
  };

  const handleChange = async (name: string, checked: boolean): Promise<void> => {
    const serialNumber = barcodeToSNFormat(name);
    if (checked) {
      powerOnTV(serialNumber);
    } else {
      powerOffTV(serialNumber);
    }
    checkStateChanges(name);
  };

  const handleRefreshTv = async (name: string, labelCode: string): Promise<void> => {
    const serialNumber = barcodeToSNFormat(name);
    setTVRefreshState((prevState) => ({ ...prevState, [labelCode]: true }));
    try {
      await refreshTvState(serialNumber);
    } finally {
      setTVRefreshState((prevState) => ({ ...prevState, [labelCode]: false }));
      checkStateChanges(name);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    setTvErrorMessage('');
    setSuccessMessage('');
  };

  const getDescriptionByTVData = (item: TVListViewItemData): DescriptionText[] =>
    [
      { text: getMerchandiseBrandWithNameForTV(item.TVData), bold: true },
      { text: item.TVData.positionInStore?.department
        ? `${item.TVData.positionInStore?.department} | ${item.TVData.positionInStore?.name}` : '' },
    ];

  const getToggleByTVData = (item: TVListViewItemData): boolean =>
    tvPowerStatus(item.TVData) === TVState.ON;

  const getCodeByTVData = (item: TVListViewItemData): string => item.TVData.labelCode;

  useEffect(() => {
    let newstatusCheckMap = new Map(statusCheckMap);
    let changed = 0;
    sortedFilteredList.forEach((currentItem) => {
      const { isUpdated, updatedStatusCheckMap } = processTvItem(currentItem, newstatusCheckMap);
      if (isUpdated) {
        changed += 1;
        newstatusCheckMap = new Map(updatedStatusCheckMap);
        if (changed > 0) {
          setStatusCheckMap(new Map(newstatusCheckMap));
        }
      }
    });
  }, [sortedFilteredList, statusCheckMap, setStatusCheckMap]);
  useEffect(() => {
    const orderList = filteredList(list);
    const sortedList = filteredListSort(orderList);
    setSortedFilteredList(sortedList);
  }, [list]);// eslint-disable-line react-hooks/exhaustive-deps
  const renderTVListItem = (item: TVListViewItemData): JSX.Element => (
    <div key={item.TVData.labelCode}>
      <PowerListItem
        description={getDescriptionByTVData(item)}
        toggle={getToggleByTVData(item)}
        barcode={getCodeByTVData(item)}
        onChange={handleChange}
        state={item?.status?.text}
        isRefreshingTVState={tvRefreshState[item.TVData.labelCode]}
        handleRefreshTv={(name): Promise<void> => handleRefreshTv(name, item.TVData.labelCode)}
      />
    </div>
  );

  return (
    <Container className={styles.container}>
      <Dialog open={open}>
        <DialogTitle id="loading-dialog">{waitingForResponse ? pleaseWaitStr : null}</DialogTitle>
        <Paper elevation={0} className={styles.progressDialog}>
          {waitingForResponse ? <CircularProgress /> : null}
          {tvErrorMessage ? (
            <>
              <Typography color="error" style={{ textAlign: 'center', padding: '1rem' }}>{tvErrorMessage}</Typography>
              <Button onClick={handleClose}>Close</Button>
            </>
          ) : null}
          {successMessage ? (
            <>
              <Typography style={{ textAlign: 'center', padding: '1rem' }}>{successMessage}</Typography>
              <Button onClick={handleClose}>Close</Button>
            </>
          ) : null}
        </Paper>
      </Dialog>
      {sortedFilteredList.length > 0 ? sortedFilteredList.map(renderTVListItem) : ''}
    </Container>
  );
};

export default TVsOffAndOn;
