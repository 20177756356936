/**
 * Default Theme
 *
 * Please follow this link to customization
 * https://material-ui.com/customization/themes
 */

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const PRIMARY_COLOR = '#ECAC00';
const SECONDARY_COLOR = '#808080';

const theme = createMuiTheme({
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: '#fff',
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    error: {
      main: '#CD0000',
    },
    warning: {
      main: '#000000',
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        background: SECONDARY_COLOR,
        color: '#fff',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#bfbfbf #fff',
        fontSize: '16px',
        textAlign: 'left',
        padding: '5px 10px',
        '&:last-child': {
          paddingRight: '10px',
        },
      },
      body: {
        fontSize: '16px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        textAlign: 'left',
        padding: '5px 10px',
        '&:last-child': {
          paddingRight: '10px',
        },
      },
      alignRight: {
        textAlign: 'left',
        padding: '5px 15px',
        '&:last-child': {
          textAlign: 'center',
        },
      },
    },
    MuiTableRow: {
      head: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: SECONDARY_COLOR,
        boxShadow: '0 1px 3px 0 rgba(0,0,0, .5)',
      },
      root: {
        '&:nth-child(even)': {
          background: '#f1f1f1',
        },
        '&:nth-child(odd)': {
          background: '#fff',
        },
      },
    },
    MuiFormControl: {
      root: {
        margin: '0 0 0 0',
        padding: '0 10px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#333',
      },
    },
    MuiInput: {
      input: {
        paddingLeft: '5px',
        paddingRight: '5px',
        borderRadius: '4px',
      },
      root: {
        color: 'rgba(0, 0, 0, .57)',
      },
    },
    MuiInputLabel: {
      formControl: {
        left: '10px',
      },
    },
    MuiList: {
      root: {
        overflow: 'auto',
      },
    },
    MuiButton: {
      root: {
        minWidth: '80px',
        padding: '7px',
        margin: '0 10px',
        color: SECONDARY_COLOR,
        textTransform: 'capitalize',
      },
      outlined: {
        borderColor: 'rgba(236, 172, 0, .5)',
      },
      sizeSmall: {
        minWidth: '40px',
        padding: '2px',
        margin: '0 2px',
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiDivider: {
      root: {
        margin: '15px 0',
        width: '100%',
      },
    },
    MuiCheckbox: {
      root: {
        color: PRIMARY_COLOR,
      },
    },
    MuiNativeSelect: {
      root: {
        background: '#f8f8f8',
      },
      select: {
        width: 'calc(100% - 37px)',
        borderRadius: '0 10px 10px 0',
      },
      icon: {
        color: '#f8f8f8',
        background: '#aaa',
        height: '100%',
        top: '0',
        borderRadius: '0 6px 6px 0',
      },
    },
    MuiCircularProgress: {
      root: {
        border: '16px',
        borderRadius: '50%',
        borderColor: PRIMARY_COLOR,
        width: '120px',
        height: '120px',
      },
    },
    MuiLinearProgress: {
      root: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 500,
        width: '100%',
      },
    },
    MuiListItemText: {
      dense: {
        fontSize: '16px',
      },
      inset: {
        paddingLeft: '15px',
      },
    },
  },
});

const defaultTheme = responsiveFontSizes(theme);

export default defaultTheme;
