import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';

interface DataLoadingDialogContentProps {
  message: string;
}

interface DataLoadingDialogProps {
  open: boolean;
  message: string;
}

const useStyles = makeStyles({
  root: {
    zIndex: -1,
  },
  dialogPaper: {
    minHeight: '25%',
    minWidth: '85%',
    overflow: 'auto',
    borderRadius: '10px',
  },
  dialogTitle: {
    padding: '0',
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  mainContent: {
    textAlign: 'center',
    color: '#0000008a',
    fontWeight: 100,
    marginBottom: '1rem',
  },
  message: {
    margin: 'auto',
    fontSize: '18px',
  },
  modalTitle: {
    width: '80%',
    textAlign: 'center',
    padding: '1rem 0',
    margin: 'auto',
  },
});

const DataLoadingDialogContent = ({ message }: DataLoadingDialogContentProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.dialogHeader}>
          <div className={classes.modalTitle}>
            <CircularProgress size={80} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.mainContent}>
        <Typography className={classes.message}>{message}</Typography>
      </DialogContent>
    </>
  );
};

export const DataLoadingDialog = ({ open, message }: DataLoadingDialogProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} style={{ zIndex: 100 }} open={open}>
      <DataLoadingDialogContent message={message} />
    </Dialog>
  );
};
