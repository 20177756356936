import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useIntl } from 'react-intl';
import { Link } from '@material-ui/core';
import styles from './index.module.scss';

interface NoDeviceSetupProps {
  noDeviceSetup?: string;
  scanDevice?: string;
}

const NoDeviceSetup = ({ noDeviceSetup, scanDevice }: NoDeviceSetupProps): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const noDevicesSetupStr = noDeviceSetup || i18n({ id: 'devices.noDevicesSetup' });
  const scanDeviceStr = scanDevice || i18n({ id: 'devices.scanDevice' });

  return (
    <>
      <div className={styles['text-message-wrapper']}>
        <pre style={{ fontFamily: 'inherit' }}>
          {noDevicesSetupStr}
        </pre>
      </div>
      <Button variant="outlined" color="primary">
        <AddIcon />
        <Link href="/devices/scan">{scanDeviceStr}</Link>
      </Button>
    </>
  );
};

export default NoDeviceSetup;
