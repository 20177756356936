import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import InVueLogo from 'shared/assets/invue-live-logo.svg';
import './index.module.scss';

interface LoginToolBarProps {
  enableBack: boolean;
  onBack(): void;
}

const LoginToolBar = (props: LoginToolBarProps): JSX.Element => {
  const { enableBack, onBack } = props;
  return (
    <AppBar position="static" color="default" elevation={0} style={{ boxShadow: '3px 3px 10px 3px #ccc' }}>
      <Toolbar className="tool-bar">
        {
          enableBack ? (
            <IconButton color="inherit" aria-label="back to login" onClick={onBack} edge="start">
              <ArrowBackIosIcon />
            </IconButton>
          ) : <span />
        }
        <div className="tool-bar">
          <img src={InVueLogo} alt="logo" className="app-logo" />
        </div>
        <span />
      </Toolbar>
    </AppBar>
  );
};

export default LoginToolBar;
