import React from 'react';
import { SecListObject } from 'modules/secure-displays/model';

interface ContextProps {
  allSSList: SecListObject;
  setAllSSList: React.Dispatch<React.SetStateAction<SecListObject>>;
  isLoading: boolean;
  hideLoader: () => void;
  showLoader: boolean;
  setShowLoader: (show: boolean) => void;
}

export const DataContext = React.createContext({} as ContextProps);
