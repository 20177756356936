import { getTenant } from 'common/services/token.service';
import httpClient from '../../../shared/services/http-client';
import { StoreModel, UserModel } from './model';

export const getAllStores = async (tenantIdParam?: string | null): Promise<StoreModel[]> => {
  const tenantId = tenantIdParam || getTenant();
  const response = await httpClient.get(`/v1/model/tenants/${tenantId}/stores`);
  return response.data;
};
export const getStoreById = async (
  tenantIdParam?: string | null, storeId?: string | null): Promise<StoreModel> => {
  const tenantId = tenantIdParam || getTenant();
  const response = await httpClient.get(`/v1/model/tenants/${tenantId}/stores/${storeId}`);
  return response.data;
};
export const getTenantByCustomerId = async (
  customerId?: string): Promise<UserModel> => {
  const response = await httpClient.get(`/v1/model/users?customerId=${customerId}`);
  return response.data[0];
};
