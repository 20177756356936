import { AxiosResponse } from 'axios';
import crypto from 'crypto';
import { setRefreshInterval, setTenant, setToken, getTenant, setEnv } from 'common/services/token.service';
import httpClient from '../../shared/services/http-client';
import { RememberMeUserInfoModel } from './storeSelect/model';
import { REMEMBER_ME_USER_INFO, LANGUAGE_PREFERENCE, AVAILABLE_LANGUAGES, LANGUAGE_SELECTION_ENABLED } from './constant';

interface LoginResponseModel {
  tenantId: string;
  role: string;
  storeIds: string[];
}

export const
  sendRegistrationTokenToServer = async (userName: string, registrationToken: string | null):
    Promise<void> => {
    const tenantId = getTenant();
    if (!userName || !registrationToken || !tenantId) {
      return;
    }

    const response: AxiosResponse = await httpClient.get(`/v1/model/tenants/${tenantId}/userRegistrationTokens?registrationToken=${registrationToken}`);
    if ((!response) || (!response.data) || response.data.length === 0) {
      const id = crypto.randomBytes(16).toString('hex');

      const userReg = {
        registrationToken,
        userId: userName,
        expired: false,
      };
      await httpClient.post(`/v1/model/tenants/${tenantId}/userRegistrationTokens/${id}`, userReg);
    }
  };

export const login = async (
  customerId: string, passcode: string): Promise<LoginResponseModel> => {
  const data = {
    customerId,
    passcode,
  };
  const response = await httpClient.post('/v1/login-live', data);
  const { tenantId, env, token } = response.data;
  setTenant(tenantId);
  setToken(token);
  setEnv(env);
  setRefreshInterval('10000');
  return response.data;
};

export const getRememberMeDetail = (): RememberMeUserInfoModel | null => {
  const userInfo = localStorage.getItem(REMEMBER_ME_USER_INFO);
  if (userInfo) {
    return JSON.parse(userInfo);
  }
  return null;
};

export const setRememberMeDetail = (userInfo: RememberMeUserInfoModel): void => {
  const existingUserInfo = getRememberMeDetail() || {};
  localStorage.setItem(REMEMBER_ME_USER_INFO, JSON.stringify({ ...existingUserInfo, ...userInfo }));
};

export const getPreferredLanguage = (): string | null => {
  const languageInfo = LANGUAGE_SELECTION_ENABLED
    ? localStorage.getItem(LANGUAGE_PREFERENCE)
    : AVAILABLE_LANGUAGES.ENGLISH;
  if (languageInfo) {
    return languageInfo;
  }
  return null;
};

export const setPreferredLanguage = (languagePreferred: string): void => {
  localStorage.setItem(LANGUAGE_PREFERENCE, LANGUAGE_SELECTION_ENABLED
    ? languagePreferred
    : AVAILABLE_LANGUAGES.ENGLISH);
};
