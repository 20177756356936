/* eslint-disable @typescript-eslint/no-explicit-any */

import httpClient from 'shared/services/http-client';
import { getTenant, getStore, getUrl } from 'common/services/token.service';
import { GatewayInfo } from 'modules/model';
import { GatewayModel, GatewayStatusModel } from './model';
import { db } from '../../firebase';

const GATEWAY_STATUS = '/v1/gateway/status';
const GATEWAY_PATH = '/v1/model/tenants/{tenantId}/gateways?serialNumber={serialNumber}&storeId={storeId}';
const GATEWAY_ENDPOINT_WITH_DOC_KEY = `/v1/model/tenants/{tenantId}/gateways/{docKey}`;
const GATEWAY_LIST_ENDPOINT = `/v1/model/tenants/{tenantId}/gateways?storeId={storeId}`;

export const getGatewaysList = async (): Promise<GatewayModel[]> => {
  const tenantId = getTenant();
  const storeId: string = getStore() || '';
  if (!tenantId) {
    throw new Error('No tenant/store id');
  }
  const url = GATEWAY_LIST_ENDPOINT
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);

  const response = await httpClient.get(url);
  return response.data;
};

// updated code of getGatewaysList
export const streamGatewaysList = (observer: any): Function => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  return db.collection(`${url}/gateways`)
    .where('storeId', '==', storeId)
    .onSnapshot(observer);
};

export const fetchGatewayStatus = async (storeId: string, gatewayId: string):
  Promise<GatewayStatusModel> => {
  const gatewayStatus: GatewayStatusModel = {
    storeId,
    gatewayId,
    status: 'offline',
  };
  try {
    const response = await httpClient.get(`${GATEWAY_STATUS}/${gatewayId}`);
    gatewayStatus.status = response?.data?.status;
    return gatewayStatus;
  } catch (error) {
    return gatewayStatus;
  }
};

export const fetchGateway = async (serialNumber: string): Promise<GatewayModel[]> => {
  const tenantId = getTenant();
  const storeId: string = getStore() || '';
  if (!tenantId) {
    throw new Error('No tenant/store id');
  }
  const url = GATEWAY_PATH
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId)
    .replace('{serialNumber}', serialNumber);

  const response = await httpClient.get(url);
  return response.data;
};

export const fetchGatewayDetailsWithStatus = async (gatewayList: GatewayInfo[]):
  Promise<string> => {
  if (gatewayList.length > 0) {
    const gateway = gatewayList.reduce((prev, current) =>
      (((prev.rssi) && (current.rssi) && (prev.rssi > current.rssi)) ? prev : current));
    const gatewayData = await fetchGateway(gateway.gatewayId);
    const name = gatewayData.length ? gatewayData[0].name : gateway.gatewayId;
    const rssiValue = `\nRSSI : ${gateway.rssi === null ? `\u00a0\u00a0 -` : `${gateway.rssi} dBm`}`;
    const snrValue = `\nSNR : ${gateway.snr === null ? `\u00a0\u00a0 -` : `${gateway.snr} dB`}`;
    return (name + rssiValue + snrValue);
  }
  return '';
};

export const deleteGateway = async (docKey: string): Promise<string> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }
  const url = GATEWAY_ENDPOINT_WITH_DOC_KEY
    .replace('{tenantId}', tenantId)
    .replace('{docKey}', docKey);
  const response = await httpClient.delete(url);
  return response.data;
};

export const updateGateway = (docKey: string, gateway: GatewayModel): Promise<void> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }
  const url = GATEWAY_ENDPOINT_WITH_DOC_KEY
    .replace('{tenantId}', tenantId)
    .replace('{docKey}', docKey);

  return httpClient.put(url, { ...gateway });
};
