/* eslint-disable react/jsx-closing-bracket-location */

import React, { useRef, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SecListObject } from 'modules/secure-displays/model';
import { DataContext } from 'modules/app/DataContext';
import { defaultTVItemsCount } from 'modules/tv-sensors/list';
import { defaultSecurityItemsCount, initialSecurityItemsList } from 'modules/secure-displays/list';
import { DataLoadingDialog } from 'shared/ui/Dialogs/DataLoadingDialog';
import { useIntl } from 'react-intl';

interface RenderWebParams {
  notificationId: string;
  silencePressed: boolean;
}

declare global {
  interface Window {
    renderWeb: (data: string, details: RenderWebParams) => void;
  }
}

const Devices = React.lazy(() => import('modules/devices'));
const LiveDashboard = React.lazy(() => import('modules/dashboard'));
const ProvisionDevice = React.lazy(() => import('../devices/provision-device'));
const OSAList = React.lazy(() => import('modules/osa/list'));
const OSADeviceView = React.lazy(() => import('modules/osa/details'));
const TVList = React.lazy(() => import('modules/tv-sensors/list'));
const TVDeviceView = React.lazy(() => import('modules/tv-sensors/details'));
const AlarmList = React.lazy(() => import('modules/alarms/list'));
const AlarmDeviceView = React.lazy(() => import('modules/alarms/details'));
const TVPowerSchedule = React.lazy(() => import('modules/tv-sensors/list/TVPowerSchedule'));
const SecureDisplaysList = React.lazy(() => import('modules/secure-displays/list'));
const SecuritySensorDeviceView = React.lazy(() => import('modules/secure-displays/details'));
const AssignedSensorListItem = React.lazy(() => import('../alarms/list/assigned-sensor-list/index'));
const GatewaysList = React.lazy(() => import('modules/gateways/list/'));
const GatewayDeviceView = React.lazy(() => import('modules/gateways/details'));
const PanicButtonsList = React.lazy(() => import('modules/panic-buttons/list'));
const PanicButtonView = React.lazy(() => import('modules/panic-buttons/details'));
const NotificationCenter = React.lazy(() => import('modules/notificationCenter'));

export const TOTAL_DASHBOARD_TILES = 3;

const FOUR_SECONDS_TIMEOUT = 4000;
const EIGHT_SECONDS_TIMEOUT = 8000;

const Routes = (): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const history = useHistory();

  const loadingStr = i18n({ id: 'common.loading' });
  const pleaseWaitStr = i18n({ id: 'common.pleaseWait' });
  const gatheringDataStr = i18n({ id: 'common.gatheringData' });
  const systemProcessingStr = i18n({ id: 'common.systemProcessing' });
  const longerWaitStr = i18n({ id: 'common.longerWait' });

  const [iconState, setIconState] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(loadingStr);
  const timeoutRefs = useRef<number[]>([]);
  const dashboardLoadingCount = useRef<number>(TOTAL_DASHBOARD_TILES);

  const initialSSList: SecListObject = {
    nonSecurityIssuesCount: 0,
    securityIssuesCount: 0,
    secSensorList: [],
    disArmedCount: 0,
    issuesCount: 0,
    tvCardData: {
      tvSensorsCount: 0,
      poweredOffTVsCount: 0,
      TVSensorsWithIssuesCount: 0,
      isSleepModeON: false,
    },
    osaCardCounts: {
      totalCount: 0,
      emptyCount: 0,
      offlineCount: 0,
      sensorsWithIssuesCount: 0,
    },
    OSASensorList: [],
    tvData: {
      count: defaultTVItemsCount,
      items: [],
      lowPowerUntilTime: '',
      lowPowerModeStatus: false,
    },
    displaySecurityData: {
      count: defaultSecurityItemsCount,
      items: initialSecurityItemsList,
      onePodCount: 0,
    },
    alarmData: {
      count: {
        totalCount: 0,
        emptyCount: 0,
        offlineCount: 0,
        sensorsWithIssuesCount: 0,
      },
      items: [],
    },
    dashboardLoadingCount: dashboardLoadingCount.current,
    rearmingTime: 0
  };
  const [allSSList, setAllSSList] = useState(() => initialSSList);

  const toggleLoader = (): void => {
    setIsLoading((prev) => !prev);
  };

  const clearTimeouts = (): void => {
    timeoutRefs.current.forEach((item) => {
      clearTimeout(item);
    });
  };

  const displayLoader = (): void => {
    toggleLoader();
    setIsLoading(true);
  };

  const hideLoader = (): void => {
    clearTimeouts();
    toggleLoader();
    setShowLoader(false);
    setIsLoading(false);
    setMessage(loadingStr);
    setAllSSList((prevState) => ({
      ...prevState,
      dashboardLoadingCount: TOTAL_DASHBOARD_TILES,
    }));
  };

  useEffect(() => {
    // TODO: separate out this timeout code in different function
    if (showLoader) {
      displayLoader();

      timeoutRefs.current[0] = window.setTimeout(() => {
        setMessage(pleaseWaitStr); // display message after next 4 seconds

        timeoutRefs.current[1] = window.setTimeout(() => {
          setMessage(gatheringDataStr); // display message after next 4 seconds

          timeoutRefs.current[2] = window.setTimeout(() => {
            setMessage(pleaseWaitStr); // display message after next 4 seconds

            timeoutRefs.current[3] = window.setTimeout(() => {
              setMessage(systemProcessingStr); // display message after next 4 seconds

              timeoutRefs.current[4] = window.setTimeout(() => {
                setMessage(pleaseWaitStr); // display message after next 4 seconds

                timeoutRefs.current[5] = window.setTimeout(() => {
                  setMessage(longerWaitStr); // display message after next 8 seconds
                }, EIGHT_SECONDS_TIMEOUT);
              }, FOUR_SECONDS_TIMEOUT);
            }, FOUR_SECONDS_TIMEOUT);
          }, FOUR_SECONDS_TIMEOUT);
        }, FOUR_SECONDS_TIMEOUT);
      }, FOUR_SECONDS_TIMEOUT);
    }

    return (): void => {
      if (showLoader) {
        timeoutRefs.current.forEach((item) => {
          clearTimeout(item);
        });
      }
    };
  }, [showLoader]);

  window.renderWeb = (data: string, details?: RenderWebParams): void => {
    const { notificationId, silencePressed } = details || {};

    if (!details) {
      history.push({
        pathname: `/${data}`,
      });
    } else {
      history.push({
        pathname: `/${data}`,
        state: {
          notificationId, silencePressed,
        },
      });
    }
  };

  return (
    <>
      { showLoader && <DataLoadingDialog open={isLoading} message={message} /> }
      <React.Suspense fallback={<CircularProgress />}>
        <DataContext.Provider value={{
          allSSList,
          setAllSSList,
          isLoading,
          hideLoader,
          showLoader,
          setShowLoader,
        }}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route exact path="/dashboard" component={LiveDashboard} />
            <Route exact path="/devices" component={Devices} />
            <Route exact path="/devices/scan" component={ProvisionDevice} />
            <Route exact path="/osa" component={OSAList} />
            <Route exact path="/osa/device/:deviceSerNum" component={OSADeviceView} />
            <Route exact path="/tvs" component={TVList} />
            <Route exact path="/tvs/device/:deviceSerNum" component={TVDeviceView} />
            <Route exact path="/alarms" component={AlarmList} />
            <Route exact path="/alarms/device/:deviceSerNum" component={AlarmDeviceView} />
            <Route exact path="/tv/schedule" component={TVPowerSchedule} />
            <Route exact path="/secure-displays" render={(props): JSX.Element => <SecureDisplaysList {...props} iconState={iconState} setIconState={setIconState} />} />
            <Route exact path="/secure-displays/device/:deviceSerNum" render={(props): JSX.Element => <SecuritySensorDeviceView {...props} setIconState={setIconState} />} />
            <Route exact path="/alarms/device/:deviceSerNum/assigned-sensor" component={AssignedSensorListItem} />
            <Route exact path="/gateways" component={GatewaysList} />
            <Route exact path="/gateways/device/:serialNumber" component={GatewayDeviceView} />
            <Route exact path="/panic-buttons" component={PanicButtonsList} />
            <Route exact path="/panic-buttons/device/:serialNumber" component={PanicButtonView} />
            <Route exact path="/notifications" component={NotificationCenter} />
          </Switch>
        </DataContext.Provider>
      </React.Suspense>
    </>
  );
};

export default Routes;
