import React, { useState, useEffect } from 'react';
import { Button, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import styles from './index.module.scss';
import { getPreferredLanguage } from '../login.service';
import { AVAILABLE_LANGUAGES, LANGUAGE_MAP } from '../constant';

interface LanguageSelectProps {
  onSave(language: string): void;
}

export const LanguageSelect = (props: LanguageSelectProps): JSX.Element => {
  const { onSave } = props;
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const availableLanguages: string[] = _.keys(AVAILABLE_LANGUAGES);

  const { formatMessage: i18n } = useIntl();
  const labels = {
    saveBtn: i18n({ id: 'common.save' }),
    selectLanguage: i18n({ id: 'common.selectLanguage' }),
  };

  useEffect(() => {
    const languagePreferred: string | null = getPreferredLanguage();
    if (languagePreferred) {
      setSelectedLanguage(languagePreferred);
    }
  }, []);

  return (
    <div className={styles.languageSelectContainer}>
      <Typography className={styles.selectLanguageLabel} variant="h4">
        {labels.selectLanguage}
      </Typography>
      <div style={{ padding: '10px' }}>
        <List component="nav" dense className={styles.languageList}>
          {_.sortBy(availableLanguages).map((language: string) => (
            <ListItem
              selected={selectedLanguage === AVAILABLE_LANGUAGES[language]}
              onClick={(): void => setSelectedLanguage(AVAILABLE_LANGUAGES[language] || '')}
              key={language}
            >
              <ListItemText
                primary={LANGUAGE_MAP[language]}
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div className={styles.btnContainer}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={!selectedLanguage}
          onClick={(): void => onSave(selectedLanguage)}
        >
          {labels.saveBtn}
        </Button>
      </div>
    </div>
  );
};
