/* eslint-disable */

import { useHistory } from "react-router-dom";

export default function LoginNativeIntegration() {
  const history = useHistory();

  window.loginSuccess = function (tenantId, storeId, token, env) {
    if (typeof ScanBarCode !== 'undefined' && ScanBarCode !== null) {
      ScanBarCode.userLoggedIn(tenantId, storeId, token, env);
    }
  };

  window.logoutSuccess = function (tenantId, userId) {
    if (typeof ScanBarCode !== 'undefined' && ScanBarCode !== null) {
      ScanBarCode.userLoggedOut(tenantId);
    }
    sessionStorage.clear();
  };

  window.renderDetailsPage = function (sensorType, serialNumber) {
    if (sensorType == 'tv') {
      sensorType = 'tvs';
    }
    if (sensorType == 'alarm') {
      sensorType = 'alarms';
    }
    if (sensorType == 'vertical' || sensorType == 'onepod') {
      sensorType = 'secure-displays';
    }
    if (sensorType == 'gateway') {
      sensorType = 'gateways';
    }
    if (sensorType == 'panicButton') {
      sensorType = 'panic-buttons';
    }
    history.push(`/${sensorType}/device/${serialNumber}`);
  };

  window.fetchVersionNumber = function () {
    if (typeof ScanBarCode !== 'undefined' && ScanBarCode !== null) {
      const versionNumber = ScanBarCode.fetchVersion();
      return versionNumber;
    }
  };

  window.sendGatewayStatus = function (status) {
    if (typeof ScanBarCode !== 'undefined' && ScanBarCode !== null) {
      ScanBarCode.gatewayStatus(status);
    }
  };

  window.sendAlarmNodeProvisionStatus = function (status) {
    if (typeof ScanBarCode !== 'undefined' && ScanBarCode !== null) {
      ScanBarCode.alarmProvisioned(status);
    }
  };

  window.setLanguage = function (language) {
    if (typeof ScanBarCode !== 'undefined' && ScanBarCode !== null) {
      ScanBarCode.setLanguage(language);
    }
  };

  return (null);
}
