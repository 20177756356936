import { createBrowserHistory } from 'history';
import reduxThunk from 'redux-thunk';
import {
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import * as reducers from './reducers';

export const history = createBrowserHistory();

let enhancer = applyMiddleware(reduxThunk, routerMiddleware(history));
if (process.env.NODE_ENV !== 'production') {
  enhancer = composeWithDevTools(enhancer);
}

export const store = createStore(
  combineReducers({ ...reducers, router: connectRouter(history) }),
  enhancer,
);
