import React, { Fragment, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import RefreshIcon from '@material-ui/icons/Refresh';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper, Box, Divider } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useIntl } from 'react-intl';
import { GatewayContext } from 'modules/app/GatewayContext';
import { isGatewayNotAssigned } from 'modules/gateways/gateway-utils';
import { useHistory } from 'react-router-dom';
import { DescriptionText } from '../../../../../shared/ui/device-list/list-item/model';
import { barcodeToSNFormat } from '../../../../../shared/services/utils';

interface PowerListItemProps {
  barcode: string;
  description: DescriptionText[];
  toggle: boolean;
  isRefreshingTVState: boolean;
  onChange: (name: string, checked: boolean) => void;
  handleRefreshTv: (name: string) => void;
  state?: string;
}

const useStyle = makeStyles({
  flex1: {
    flex: '1',
  },
  textBold: {
    fontWeight: 'bold',
    color: 'rgb(123,123,123)',
  },
  textNormal: {
    color: 'rgb(123,123,123)',
  },
  paper: {
    marginBottom: '1.5rem',
    backgroundColor: '#f1f1f1',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.7rem 1rem',
    justifyContent: 'space-between',
  },
  divider: {
    margin: 0,
    backgroundColor: 'rgb(181, 181, 181)',
  },
  tvStatus: {
    color: 'rgb(135, 135, 135)',
    marginRight: 10,
    textTransform: 'capitalize',
  },
  tvStatusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const PowerListItem = (props: PowerListItemProps): JSX.Element => {
  const styles = useStyle(props);
  const history = useHistory();
  const {
    barcode, toggle, description, state, isRefreshingTVState,
    handleRefreshTv,
  } = props;

  const { formatMessage: i18n } = useIntl();
  const labels = {
    tvOn: i18n({ id: 'devices.on' }),
    tvOff: i18n({ id: 'devices.off' }),
  };
  const { gatewayCurrentStatus } = useContext(GatewayContext);

  const pathSN = (code: string): string => `/tvs/device/${barcodeToSNFormat(code || '0000')}`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    props.onChange(event.target.name, event.target.checked);
  };

  const getIcon = (currentState: string): JSX.Element => {
    if (currentState === 'waiting' || isRefreshingTVState) {
      return <CircularProgress size={24} />;
    } if (currentState === 'refresh') {
      return <RefreshIcon color="primary" onClick={(): void => handleRefreshTv(barcode)} />;
    } if (currentState === 'error') {
      return <ErrorOutlineIcon color="error" />;
    } if ([labels.tvOff, labels.tvOn].includes(currentState)) {
      return (
        <Switch
          name={barcode}
          checked={toggle}
          onChange={handleChange}
          color="primary"
          disabled={isGatewayNotAssigned(gatewayCurrentStatus)}
        />
      );
    }
    return <></>;
  };

  const handleRedirect = (index: number): void => {
    if (index === 0) {
      history.push(pathSN(barcode));
    }
  };

  let status = labels.tvOff;
  if (state) {
    status = state;
  } else if (toggle) {
    status = labels.tvOn;
  }

  return (
    <Paper elevation={3} className={styles.paper}>
      {description.map((item: DescriptionText, index: number) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div key={item.text} color="inherit" role="button" tabIndex={0} onClick={(): void => handleRedirect(index)}>
          <Fragment key={item.text}>
            <Box className={styles.box}>
              <Typography
                key={item.text}
                variant="body1"
                noWrap
                className={`${styles.flex1} ${item.bold ? styles.textBold : styles.textNormal}`}
              >
                {item.text}
              </Typography>
              <div className={styles.tvStatusContainer}>
                {item.bold
                  ? (
                    <KeyboardArrowRightIcon />
                  )
                  : (
                    <>
                      <Typography align="center" className={styles.tvStatus}>
                        {status}
                      </Typography>
                      {state ? getIcon(state?.trim()) : ''}
                    </>
                  )}
              </div>
            </Box>
            {item.bold && <Divider className={styles.divider} />}
          </Fragment>
        </div>
      ))}
    </Paper>
  );
};

export default PowerListItem;
