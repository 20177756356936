import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import ListItem from 'shared/ui/device-list/list-item';
import { DescriptionText, ItemStatus } from 'shared/ui/device-list/list-item/model';
import { getMerchandiseBrandWithNameForTV } from 'modules/tv-sensors/tv-utils';
import { TVSensor } from '../../model';
import styles from './list-view.module.scss';
import { barcodeToSNFormat } from '../../../../shared/services/utils';

export interface TVListViewItemData {
  description?: DescriptionText[];
  TVData: TVSensor;
  status: ItemStatus;
  className?: string;
  toggle?: HTMLElement;
}

export interface TVListViewProps {
  list: TVListViewItemData[];
  headers?: string[];
}

const TVListView: React.FC<TVListViewProps> = ({
  list,
}: TVListViewProps): JSX.Element => {
  const getDescriptionByTVData = (item: TVSensor): DescriptionText[] =>
    [
      { text: getMerchandiseBrandWithNameForTV(item), bold: true },
      { text: item.positionInStore?.department
        ? `${item.positionInStore?.department} | ${item.positionInStore?.name}` : '' },
    ];

  const getPathWithSerialNumber = (item: TVSensor): string => `/tvs/device/${barcodeToSNFormat(item.labelCode || '0000')}`;

  const renderListItem = ({ description, status, TVData }: TVListViewItemData): JSX.Element => (
    <Link
      to={{ pathname: getPathWithSerialNumber(TVData) }}
      key={TVData.labelCode}
      className={styles.tvListLink}
    >
      <ListItem
        description={description || getDescriptionByTVData(TVData)}
        status={status}
      />
    </Link>
  );

  return (
    <Container className={styles.container}>
      {list.map(renderListItem)}
    </Container>
  );
};

export default TVListView;
